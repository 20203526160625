import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Tabs, TabList, Tab } from 'react-tabs';
import Chart from '../../components/Chart';
import styles from './YourResult.module.css';
import PeerReviewCard from './PeerReviewCard';
import { getAverageLevelByMacroSkill } from '../../utils/skills';
import Card from '../../components/Card';
import ConditionalWrapper from '../../utils/ConditionalWrapper';
import useDefaultMedia from '../../hooks/useDefaultMedia';

const cx = classNames.bind(styles);

const CHART_TITLES = [
  'A rising star 🌟!',
  'A journey(wo)man 💪!',
  'A black belt 🥋!',
];

const NUMBER_OF_SKILLS_LEVELS = 3;

function YourResult({ result }) {
  const isWide = useDefaultMedia();

  const [showDetail, setShowDetail] = useState(isWide);

  const fullValues = result.map((item) => (item.level === 0 ? null : item.level));
  const fullCategories = result.map((item) => item.Skill.discipline);

  const overviewData = getAverageLevelByMacroSkill(result);
  const overviewValues = overviewData.map((item) => (item.averageLevel === 0 ? null : item.averageLevel));
  const overviewCategories = overviewData.map((item) => item.macroSkill);

  const data = showDetail ? fullValues : overviewValues;
  const categories = showDetail ? fullCategories : overviewCategories;

  const series = [
    {
      data,
      name: 'You',
    },
  ];

  const getChartTitle = () => {
    const levels = series[0].data;

    const expertLeadMasterCount = levels.filter((item) => item > 3).length;
    if (expertLeadMasterCount > 2) return CHART_TITLES[2];

    const seniorCount = levels.filter((item) => item === 3).length;
    if (seniorCount > NUMBER_OF_SKILLS_LEVELS) return CHART_TITLES[1];

    const noviceJuniorCount = levels.filter((item) => item && item < 3).length;
    if (noviceJuniorCount > NUMBER_OF_SKILLS_LEVELS) return CHART_TITLES[0];

    return '';
  };

  if (!isWide) {
    return (
      <div className={cx('you-tab')}>
      <h2 className={cx('you-tab-title')}>Skill blueprint, mobile version</h2>
        <div className={cx('chart-container')}>
          <Chart
            categories={categories}
            series={series}
            details
            height="100%"
            title={getChartTitle()}
            columnWidth={showDetail ? '40%' : '10%'}
          />
        </div>
        <PeerReviewCard />
      </div>
    );
  }

  return (
    <Card className={cx('card')}>
      <div className={cx('header')}>
        <h1 className={cx('title')}>Your skills blueprint</h1>

        <Tabs className={cx('tabs')}>
          <TabList className={cx('tab-list')}>
            <Tab
              className={cx('tab')}
              selectedClassName={cx('tab-active')}
              onClick={() => setShowDetail(true)}
            >
              Detail
            </Tab>
            <Tab
              className={cx('tab')}
              selectedClassName={cx('tab-active')}
              onClick={() => setShowDetail(false)}
            >
              Overview
            </Tab>
          </TabList>
        </Tabs>
      </div>
      <div className={cx('chart-container')}>
        <Chart
          categories={categories}
          series={series}
          details
          height="100%"
          title={getChartTitle()}
          columnWidth={showDetail ? '40%' : '10%'}
        />
      </div>
      <PeerReviewCard />
    </Card>
  );
}

YourResult.propTypes = {
  result: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default YourResult;
