import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Quizz from './Quizz';
import { API_URL } from '../../Constant';
import NavBar from '../../components/NavBar';
import StickyHeader from './StickyHeader';
import authFetch from '../../utils/authFetch';
import style from './SkillsMapping.module.css';
import useDefaultMedia from '../../hooks/useDefaultMedia';

const SkillsMapping = ({ update }) => {
  const history = useHistory();
  const isWide = useDefaultMedia();

  const { uuid } = useParams();

  const saveSkillMapping = async (levels) => {
    try {
      const response = await authFetch(`${API_URL}/skills`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(levels),
      });

      // TODO: Issue #49: Handle errors and show something to the user
      if (response.ok) {
        const { result, userId } = await response.json();
        localStorage.setItem('userId', userId); // TODO: remove it when authentication is added
        if (update) {
          toast.success('Skills mapping updated!', {
            position: 'bottom-center',
          });
          history.push(`/profile/${uuid}`, { result });
        } else {
          history.push(`${history.location.pathname.replace(/\/+$/, '')}/result`, { result });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isWide && <NavBar />}
      <div style={{ height: 'initial' }}>
        <StickyHeader who="your" />
        <div className={style.quizz}>
          <Quizz
            title={update ? 'Update your proficiency in these skills' : 'Evaluate your proficiency in these skills'}
            onSave={saveSkillMapping}
            update={update}
          />
        </div>
      </div>
    </>
  );
};

SkillsMapping.defaultProps = {
  update: false,
};

SkillsMapping.propTypes = {
  update: PropTypes.bool,
};

export default SkillsMapping;
