import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import styles from './Signup.module.css';

import { AUTH_URL } from '../../Constant';
import Button from '../../components/Button';

const cx = classNames.bind(styles);

function Footer() {
  return (
    <>

      <div className={cx('footer')}>
        <a
          className={cx('sign-up-button-link-container')}
          href={`${AUTH_URL}/linkedin/sign-up`}
        >
          <Button className={cx('sign-up-button')}>Sign-up with LinkedIn</Button>
        </a>
        <p className={cx('link-text')}>
          By clicking you agree to the
          {' '}
          <Link
            className={cx('link')}
            to="/term-of-use"
          >
            Terms of Use
          </Link>
          {' '}
          &
          {' '}
          <Link
            className={cx('link')}
            to="/privacy-policy"
          >
            Privacy Policy
          </Link>
          .
          {' '}
          Have an account?
          {' '}
          <a
            className={cx('link')}
            href={`${AUTH_URL}/linkedin/sign-in`}
          >
            Sign in.
          </a>
        </p>
      </div>
    </>
  );
}

export default Footer;
