import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { AUTH_URL } from '../../Constant';
import styles from './NavBar.module.css';
import AvatarMenu from './AvatarMenu';
import { AuthContext } from '../../contexts/AuthContext';

const cx = classNames.bind(styles);

function NavBar() {
  const isAuth = useContext(AuthContext);

  return (
    <div className={cx('navbar')}>

      {isAuth
        ? (
          <Link to="/profile/me" className={cx('title')}>
            <h2 className={cx('title')}>Whatuxru?</h2>
          </Link>
        )
        : (
          <h2 className={cx('title')}>
            Whatuxru?
          </h2>
        )}
      {isAuth
        ? <AvatarMenu /> : (
          <a
            className={cx('sign-in')}
            href={`${AUTH_URL}/linkedin/sign-in`}
          >
            Sign in
          </a>
        )}
    </div>
  );
}

export default NavBar;
