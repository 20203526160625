import React from 'react';
import { PUBLIC_FOLDER } from '../../Constant';
import ImagePage from '../../components/ImagePage';

// @grant window.close
function Invalid() {
  return (
    <ImagePage
      title="Sorry, this test is not for you."
      imgSrc={`${PUBLIC_FOLDER}/img/invalid.svg`}
      description="This skills mapping is dedicated to people knowledgeable in UX and UI."
      primaryButtonText="Learn what is UX"
      primaryButtonOnclick={() => { window.location.href = 'https://www.interaction-design.org/literature/topics/ux-design'; }}
    />
  );
}

export default Invalid;
