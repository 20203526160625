import React, { useState, useEffect } from 'react';
import { FiBarChart2 } from 'react-icons/fi';
import { useWindowScroll } from 'react-use';
import Modal from './LevelsInfoModal';
import style from './LevelsInfoModalButton.module.css';
import Button from '../../components/Button/Button';

const SCROLL_BREAKPOINT = 500;

function LevelsInfoModalButton() {
  const { y } = useWindowScroll();
  const height = document.body.scrollHeight - window.innerHeight;

  const [show, setShow] = useState(true);
  useEffect(() => setShow(y < height - SCROLL_BREAKPOINT), [y, height]);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (!show) return null;

  return (
    <>
      <Button className={style.informationButton} onClick={handleOpen}>
        <div className={style.informationText}>
          <FiBarChart2 className={style.barChartIcon} />
          <span>Novice? Junior? Senior? 🧐</span>
        </div>
      </Button>
      <Modal open={open} onClose={handleClose} />
    </>
  );
}

export default LevelsInfoModalButton;
