import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { PUBLIC_FOLDER } from '../../Constant';
import ImagePage from '../../components/ImagePage';

// @grant window.close
function Invalid({ notKnowledgeable }) {
  const history = useHistory();
  const { name } = useLocation().state;

  return (
    <ImagePage
      title="Sorry this test is not for you."
      imgSrc={`${PUBLIC_FOLDER}/img/invalid.svg`}
      description={notKnowledgeable
        ? 'This peer assessment is dedicated to people familiar with the field of UX-UI.'
        : `This peer assessment is dedicated to people who have witnessed ${name}'s UX-UI skills. \n Nevertheless, we invite you to map your skills and start a peer loop.`}
      primaryButtonText={notKnowledgeable
        ? 'Learn what is UX'
        : 'Start skills mapping'}
      primaryButtonOnclick={() => {
        if (notKnowledgeable) {
          window.location.href = 'https://www.interaction-design.org/literature/topics/ux-design';
        } else {
          history.push('/');
        }
      }}
    />
  );
}

Invalid.defaultProps = {
  notKnowledgeable: false,
};

Invalid.propTypes = {
  notKnowledgeable: PropTypes.bool,
};

export default Invalid;
