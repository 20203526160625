import React from 'react';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router';
import styles from './PeerDeleted.module.css';
import { PUBLIC_FOLDER, AUTH_URL } from '../../Constant';
import Button from '../../components/Button';
import ImagePage from '../../components/ImagePage';

const cx = classNames.bind(styles);

function NoUserFound() {
  const history = useHistory();

  const onPrimaryButtonClick = () => history.push('/skills-mapping/screening');
  return (
    <ImagePage
      title="Your peer has deleted his account."
      imgSrc={`${PUBLIC_FOLDER}/img/sign_up_declined.png`}
      description={`It is not possible to process to the peer review.${'\n'}
        Nevertheless, we invite you to map your UX and UI skills.`}
      primaryButtonText="Start skills mapping"
      primaryButtonOnclick={onPrimaryButtonClick}
    />
  );
}

export default NoUserFound;
