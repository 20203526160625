import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from './Settings.module.css';
import Card from '../../components/Card';
import Button from '../../components/Button';
import { AUTH_URL, API_URL } from '../../Constant';
import SlidingCheckbox from '../../components/SlidingCheckbox';
import authFetch from '../../utils/authFetch';

const cx = classNames.bind(styles);

function AuthSettings() {
  const history = useHistory();
  const [user, setUser] = useState();

  useEffect(() => {
    (async () => {
      const response = await authFetch(`${API_URL}/users/me`);
      if (response.ok) {
        const data = await response.json();
        setUser(data);
      }
      if (response.status === 401) {
        history.replace('/');
      }
    })();
  }, []);

  const handleCheckboxChange = async (evt) => {
    const updatedUser = { ...user, mailNotificationEnabled: evt.target.checked };
    try {
      const response = await authFetch(`${API_URL}/users/profile/edit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedUser),
      });

      if (response.ok) {
        setUser(updatedUser);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteMyAccount = async () => {
    const deletionConfirmed = window.confirm('Are you sure you want to delete your account ?');

    if (deletionConfirmed) {
      try {
        const deletionResponse = await authFetch(`${API_URL}/users`, {
          method: 'DELETE',
        });

        if (deletionResponse.status !== 204) return toast.error('Failed to delete your account.');

        return toast.success('Account successfully deleted. You are redirected.', {
          position: 'bottom-center',
          autoClose: 5000,
          onOpen: () => setTimeout(
            () => window.location.assign(`${AUTH_URL}/linkedin/logout`),
            5000,
          ),
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  if (!user) return <p>Loading...</p>;

  return (
    <>
      <h2>Sign out</h2>
      <Card>
        <div className={cx('flex-container')}>
          <p>To connect again, sign-in using LinkedIn.</p>
          <a href={`${AUTH_URL}/linkedin/logout`}>
            <Button className={cx('button')}>Sign out</Button>
          </a>
        </div>
      </Card>
      <h2>Account</h2>
      <Card>
        <div className={cx('flex-container', 'flex-column')}>
          <div className={cx('card-header')}>
            <h3>E-mail notifications</h3>
            <SlidingCheckbox
              name="mailNotificationEnabled"
              checked={user.mailNotificationEnabled}
              onChange={handleCheckboxChange}
            />
          </div>
          <p>
            We use the email tied to your LinkedIn. We send an email when a
            new peer review is ready.
          </p>
        </div>
      </Card>
      <Card>
        <div className={cx('flex-container', 'flex-column')}>
          <div className={cx('card-header')}>
            <h3>Delete your account</h3>
            <Button className={cx('button')} onClick={deleteMyAccount}>Delete</Button>
          </div>
          <p>
            All your information including self & peer assessments will be deleted.
            Please be certain.
          </p>
        </div>
      </Card>
      <Card>
        <div>
          <h3>Name and Surname</h3>
          <p>
            These information are pulled from LinkedIn. We do not handle nor
            store any of your credentials.
          </p>
        </div>
      </Card>
      <Card>
        <div>
          <h3>Password</h3>
          <p>
            We use LinkedIn login API. We do not handle nor store any of your
            credentials. Thus, you cannot modify your password.
          </p>
        </div>
      </Card>
    </>
  );
}

export default AuthSettings;
