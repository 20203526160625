import React from 'react';
import classNames from 'classnames/bind';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import styles from './Signup.module.css';

import useDefaultMedia from '../../hooks/useDefaultMedia';
import NavBar from '../../components/NavBar';
import Footer from './Footer';
import UseCases from './UseCases';
import Features from './Features';

const cx = classNames.bind(styles);

function Signup() {
  const history = useHistory();
  const isWide = useDefaultMedia();

  if (isWide) {
    return (
      <>
        {isWide && <NavBar />}
        <div className={cx('main')} role="none">
          <div className={cx('header')}>
            <button
              type="button"
              className={cx('back-button')}
              onClick={history.goBack}
            >
              <MdKeyboardArrowLeft size={24} />
              <p>Back</p>
            </button>
          </div>
          <div className={cx('content-container')}>
            <div className={cx('content')}>
              <div className={cx('signup-card')}>
                <div className={cx('signup-card-header')}>
                  <h1 className={cx('signup-title')}>
                    Help yourself, it&apos;s free
                    {' '}
                    <span role="img" aria-label="party">
                      🥳
                    </span>
                  </h1>
                </div>
                <div className={cx('signup-content')}>
                  <UseCases />
                  <Features />
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className={cx('main')} role="none">
        <div className={cx('header')}>
          <button
            type="button"
            className={cx('back-button')}
            onClick={history.goBack}
          >
            <MdKeyboardArrowLeft size={24} />
            <p>Back</p>
          </button>
        </div>
        <div className={cx('content')}>
          <Tabs className={cx('tabs')}>
            <TabList className={cx('tab-list')}>
              <Tab className={cx('tab')} selectedClassName={cx('tab-active')}>
                Use cases
              </Tab>
              <Tab className={cx('tab')} selectedClassName={cx('tab-active')}>
                Features
              </Tab>
            </TabList>
            <TabPanel selectedClassName={cx('tab-panel', 'why-signup')}>
              <UseCases />
            </TabPanel>
            <TabPanel selectedClassName={cx('tab-panel', 'why-linkedin')}>
              <Features />
            </TabPanel>
          </Tabs>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Signup;
