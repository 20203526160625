import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useParams } from 'react-router';

export const QuizzContext = React.createContext();

const QuizzContextProvider = ({ children }) => {
  const [levels, setLevels] = useState([]);

  // Only needed for peer reviews
  const [strengths, setStrenghts] = useState('');
  const [improvementAreas, setImprovementAreas] = useState('');
  const [scopeOfWork, setScopeOfWork] = useState([]);
  const [peerSeniorityLevel, setPeerSeniorityLevel] = useState();
  const [userSeniorityLevel, setUserSeniorityLevel] = useState();
  const [anecdote, setAnecdote] = useState('');

  const { uuid } = useParams();

  const syncPeerReviewStorage = () => {
    const peerReview = localStorage.getItem(uuid);
    if (peerReview) {
      const { quizz } = JSON.parse(peerReview);

      if (quizz) {
        setLevels(quizz.levels);
        setStrenghts(quizz.strengths);
        setImprovementAreas(quizz.improvementAreas);
        setScopeOfWork(quizz.scopeOfWork);
        setPeerSeniorityLevel(quizz.peerSeniorityLevel);
        setUserSeniorityLevel(quizz.userSeniorityLevel);
        setAnecdote(quizz.anecdote);
      }
    }
  };

  useEffect(syncPeerReviewStorage, [uuid]);

  return (
    <QuizzContext.Provider value={{
      levels,
      setLevels,
      strengths,
      setStrenghts,
      improvementAreas,
      setImprovementAreas,
      scopeOfWork,
      setScopeOfWork,
      peerSeniorityLevel,
      setPeerSeniorityLevel,
      userSeniorityLevel,
      setUserSeniorityLevel,
      anecdote,
      setAnecdote,
    }}
    >
      {children}
    </QuizzContext.Provider>
  );
};

QuizzContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default QuizzContextProvider;
