import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { FaPlus } from 'react-icons/fa';
import styles from './PeerReviewButton.module.css';
import Button from '../../components/Button';

import Modal from './PeerReviewModal';

const cx = classNames.bind(styles);

function PeerReviewButton({ peerReviewLink }) {
  const [startPeerReview, setStartPeerReview] = useState(false);

  const handleOpen = () => {
    setStartPeerReview(true);
  };
  const handleClose = () => {
    setStartPeerReview(false);
  };

  return (
    <>
      <Button
        className={cx('btn-start-peer-review')}
        type="button"
        onClick={handleOpen}
      >
        <div className={cx('btn-content')}>
          <FaPlus />
          Start peer review
        </div>
      </Button>
      <Modal open={startPeerReview} onClose={handleClose} peerReviewLink={peerReviewLink} />

    </>
  );
}

PeerReviewButton.propTypes = {
  peerReviewLink: PropTypes.string.isRequired,
};

export default PeerReviewButton;
