import React from 'react';
import classNames from 'classnames/bind';
import {
  Tabs, TabList, Tab, TabPanel,
} from 'react-tabs';
import { FaArrowDown } from 'react-icons/fa';
import { useLocation, useHistory } from 'react-router-dom';
import styles from './LegalMentions.module.css';
import useDefaultMedia from '../../hooks/useDefaultMedia';
import BackButton from '../../components/BackButton';
import Card from '../../components/Card';
import ScrollToTop from '../../components/ScrollToTop';
import NavBar from '../../components/NavBar';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import Impressum from './Impressum';

const cx = classNames.bind(styles);

const TABS_PATHNAME = ['/term-of-use', '/privacy-policy', '/impressum'];

function LegalMentions() {
  const { pathname } = useLocation();
  const history = useHistory();

  const isWide = useDefaultMedia();

  const onTabSelect = (index) => {
    history.replace(TABS_PATHNAME[index]);
  };

  return (
    <>
      <ScrollToTop />
      {isWide && <NavBar />}
      <BackButton>Back</BackButton>
      <div className={cx('content')}>
        <h1>
          <FaArrowDown />
          Legal mentions
        </h1>
        <Tabs defaultIndex={TABS_PATHNAME.indexOf(pathname)} onSelect={onTabSelect}>
          <TabList className={cx('tab-list')}>
            <Tab className={cx('tab')} selectedClassName={cx('tab-active')}>
              Terms
            </Tab>
            <Tab className={cx('tab')} selectedClassName={cx('tab-active')}>
              Privacy
            </Tab>
            <Tab className={cx('tab')} selectedClassName={cx('tab-active')}>
              Impressum
            </Tab>
          </TabList>
          <TabPanel>
            <TermsAndConditions />
          </TabPanel>
          <TabPanel>
            <PrivacyPolicy />
          </TabPanel>
          <TabPanel>
            <Impressum />
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
}

export default LegalMentions;
