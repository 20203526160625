import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import EditingContext from './EditingContext';
import SlidingCheckbox from '../../components/SlidingCheckbox';

const SITUATIONS = [
  'student',
  'freshGraduate',
  'intern',
  'employee',
  'freelance',
  'lecturer',
  'speaker',
];

function CurrentSituations({ currentSituations, className }) {
  const { editing, setEditing } = useContext(EditingContext);

  const handleCheckboxChanges = (evt) => {
    const { name, checked } = evt.target;
    const newCurrentSituations = new Set([...editing.currentSituations]);

    if (checked) {
      newCurrentSituations.add(name);
    } else {
      newCurrentSituations.delete(name);
    }

    setEditing({ ...editing, currentSituations: Array.from(newCurrentSituations) });
  };

  if (!editing) {
    return (
      <>
        <h3>Current roles</h3>
        {currentSituations && currentSituations.map((item) => (
          <div className={className}>
            <p>{item}</p>
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      <h3>Current roles</h3>
      {SITUATIONS.map((item) => (
        <div key={item} className={className}>
          <p>{item}</p>
          <SlidingCheckbox
            name={item}
            checked={editing.currentSituations.includes(item)}
            onChange={handleCheckboxChanges}
          />
        </div>
      ))}
    </>
  );
}

CurrentSituations.propTypes = {
  currentSituations: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
};

CurrentSituations.defaultProps = {
  currentSituations: [],
  className: '',
};

export default CurrentSituations;
