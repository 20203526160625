import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { MdPlace } from 'react-icons/md';

import Chart from '../../components/Chart';
import styles from './UserProfile.module.css';
import Button from '../../components/Button';
import EditingContext from './EditingContext';
import Editable from './Editable';
import LinkList from './LinkList';
import ExperiencesTabPanel from './ExperiencesTabPanel';
import useDefaultMedia from '../../hooks/useDefaultMedia';
import PeerReviewButton from './PeerReviewButton';

const cx = classNames.bind(styles);

function UserProfile({
  user, userSkills, editable, peerReviews,
}) {
  const { editing, startEditing, saveEdit } = useContext(EditingContext);
  const isWide = useDefaultMedia();

  const simplePeerReviews = peerReviews.map((el) => el.values);
  const peerReviewsAverage = userSkills.map((el) => Object.keys(el)[0]).map((name) => simplePeerReviews.reduce((sum, el) => sum + el.find((o) => o[name] != null)[name], 0) / simplePeerReviews.length);

  const categories = userSkills.map((item) => Object.keys(item)[0].split(' '));

  return (
    <>
      {editing === null && !isWide && user.isProfileOwner && <PeerReviewButton peerReviewLink={`${window.location.origin}/peer-review/${user.key}`} />}
      <div className={cx('card')}>
        <div className={cx('location')}>
          <MdPlace color="#FFEC60" size={20} />
          <Editable value="city" user={user} />
          <div>,&nbsp;</div>
          <Editable value="country" user={user} />
        </div>
        <div className={cx('name')}>
          {`${user?.firstname || 'Unknown'} ${user?.lastname || 'Unknown'}`}
        </div>
        <div className={cx('job-and-edit')}>
          <Editable
            placeholder={user.isProfileOwner ? 'Add a job description' : 'No job description'}
            value="jobDescription"
            className="job"
            user={user}
          />
          {editable && editing === null && <Button className="smallButton" onClick={startEditing}>Edit</Button>}
        </div>
        <Editable
          value="description"
          className="description"
          user={user}
          placeholder={user.isProfileOwner ? 'Explain what makes you and your UX/UI process special.' : ''}
        />
      </div>
      <LinkList links={user.links} isOwner={user.isProfileOwner} onAddClick={startEditing} />
      <Tabs>
        <TabList className={cx('tab-list')}>
          {!isWide && (
          <Tab className={cx('tab')} selectedClassName={cx('tab-active')}>
            Mapping
          </Tab>
          )}
          <Tab className={cx('tab')} selectedClassName={cx('tab-active')}>
            Experience
          </Tab>
        </TabList>
        {!isWide && (
        <TabPanel>
          <div className={cx('card')}>
            {user.isProfileOwner && (
            <div className={cx('float-right')}>
              <Link className="no-underline" to={`/skills-mapping/update/${user.key}`}>
                <Button className={cx('modify-btn')}>Update</Button>
              </Link>
            </div>
            )}
            <Chart
              categories={categories}
              series={[
                { name: 'You', data: userSkills.map((item) => Object.values(item)[0]) },
                {
                  name: 'Peer average',
                  data: peerReviewsAverage,
                },
              ]}
            />
          </div>
        </TabPanel>
        )}
        <TabPanel>
          <ExperiencesTabPanel user={user} />
        </TabPanel>
      </Tabs>
      {editing !== null && (
        <Button
          className={cx('saveButton')}
          color="tertiary"
          onClick={saveEdit}
        >
          <div className={cx('saveButtonText')}>
            <span>Save changes</span>
          </div>
        </Button>
      )}
    </>
  );
}

UserProfile.propTypes = {
  user: PropTypes.object.isRequired,
  userSkills: PropTypes.arrayOf(PropTypes.object).isRequired,
  editable: PropTypes.bool.isRequired,
  peerReviews: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UserProfile;
