import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { AUTH_URL } from '../Constant';

export const AuthContext = React.createContext();

const AuthContextProvider = ({ children }) => {
  const { pathname } = useLocation();

  const isloggedIn = async () => {
    const response = await fetch(`${AUTH_URL}/check`, {
      credentials: 'include',
    });
    return response.ok;
  };

  const [isAuth, setIsAuth] = useState();

  useEffect(() => {
    isloggedIn().then(setIsAuth);
  }, [pathname]);

  return (
    <AuthContext.Provider value={isAuth}>
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AuthContextProvider;
