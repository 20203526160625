import React, { useState } from 'react';
import PropTypes from 'prop-types';
import className from 'classnames/bind';
import styles from './FeedBackSelect.module.css';
import InputText from '../../components/InputText';
import { SCOPE_OF_WORK } from '../../Constant';

const cx = className.bind(styles);

function FeedBackSelect({
  title, description, options, onSelect, multipleSelect, selected,
}) {
  const [isOtherSelected, setIsOtherSelected] = useState(multipleSelect && selected && !selected.every((item) => SCOPE_OF_WORK.includes(options[item])));
  const handleSelect = (event) => {
    const newSelectedItem = +event.target.value;
    if (selected === newSelectedItem) {
      onSelect(undefined);
    } else {
      onSelect(newSelectedItem);
    }
  };

  const optionButtonIndex = options.findIndex((item) => item === 'Other');
  const handleMultipleSelect = (event) => {
    const newSelectedItem = +event.target.value;
    let newSelectedList;
    if (selected.includes(newSelectedItem)) {
      newSelectedList = selected.filter((item) => item !== newSelectedItem);
    } else if (newSelectedItem === optionButtonIndex) {
      newSelectedList = selected;
    } else {
      newSelectedList = [...selected, newSelectedItem];
    }

    onSelect(newSelectedList);
  };
  const handleOtherSelect = (event) => {
    const listWithoutCustomOption = selected.filter((item) => SCOPE_OF_WORK.includes(options[item]));
    onSelect(listWithoutCustomOption);

    setIsOtherSelected(!isOtherSelected);
  };

  const handleOtherInputChange = (event) => {
    const newOtherText = event.target.value || selected.find((item) => !SCOPE_OF_WORK.includes(options[item]));
    const listWithoutCustomOption = selected.filter((item) => SCOPE_OF_WORK.includes(options[item]));
    const newSelectedArray = [...listWithoutCustomOption, newOtherText];
    onSelect(newSelectedArray);
  };

  return (
    <div className={cx('container')}>
      <h3>{title}</h3>
      <p>{description}</p>
      <div className={cx('select')}>
        {options.map((item, index) => (
          <button
            key={item}
            type="button"
            className={cx('option', {
              selected: multipleSelect
                ? selected.includes(index)
                : index === selected,
            })}
            onClick={multipleSelect ? handleMultipleSelect : handleSelect}
            value={index}
          >
            {item}
          </button>
        ))}
        {multipleSelect && (
        <button
          key="Other"
          type="button"
          className={cx('option', {
            selected: isOtherSelected,
          })}
          onClick={handleOtherSelect}
        >
          Other
        </button>
        )}
      </div>
      {multipleSelect && isOtherSelected && (
        <div className={cx('input-container')}>
          <InputText
            onChange={handleOtherInputChange}
            placeholder="Other scope..."
            value={selected.find((item) => !SCOPE_OF_WORK.includes(options[item]))}
          />
        </div>
      )}
    </div>
  );
}

FeedBackSelect.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  multipleSelect: PropTypes.bool,
};

FeedBackSelect.defaultProps = {
  multipleSelect: false,
};

export default FeedBackSelect;
