import React from 'react';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import Menu, { Item } from 'rc-menu';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { API_URL, AUTH_URL } from '../../Constant';
import styles from './NavBar.module.css';

const cx = classNames.bind(styles);

function AvatarMenu() {
  const menu = (
    <Menu selectable={false}>
      <Item className={cx('menu-item')}>
        <Link to="/profile/me">My profile</Link>
      </Item>
      <Item className={cx('menu-item')}>
        <Link to="/settings">Settings</Link>
      </Item>
      <Item className={cx('menu-item')}>
        <a href={`${AUTH_URL}/linkedin/logout`}>Sign out</a>
      </Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <img
        alt="avatar"
        className={cx('avatar')}
        src={`${API_URL}/users/my-avatar`}
      />
    </Dropdown>
  );
}

export default AvatarMenu;
