import React from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useWindowScroll } from 'react-use';
import Quizz from '../SkillsMapping/Quizz';
import StepBackButton from './StepBackButton';
import styles from './PeerReviewQuizz.module.css'; import NavBar from '../../components/NavBar';
import ScrollProgressBar from '../../components/ScrollProgressBar';
import SaveButton from './SaveButton';
import useDefaultMedia from '../../hooks/useDefaultMedia';

const PeerReviewQuizz = () => {
  const history = useHistory();
  const { uuid } = useParams();
  const { y } = useWindowScroll();
  const { name } = useLocation().state;
  const isWide = useDefaultMedia();
  const goToFeedbacks = (levels) => history.push('step-3', { skills: levels, uuid, name });

  return (
    <>
      {isWide && <NavBar />}
      <div style={{ height: 'initial' }}>
        <div className={styles.header}>
          <ScrollProgressBar />
          <div className={styles.btnContainer}>
            <StepBackButton step={2} totalSteps={4} />
            <SaveButton />
          </div>
        </div>
        <div className={styles.quizz}>
          <Quizz
            title={`Evaluate ${name}'s proficiency in these skills.`}
            onSave={goToFeedbacks}
            who={name}
            buttonText="Add feedback"
          />
        </div>
      </div>
    </>
  );
};

export default PeerReviewQuizz;
