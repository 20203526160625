import React from 'react';
import ReactDOM from 'react-dom';
import './style.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import initHotjar from './utils/hotjar';
import ScrollToTop from './components/ScrollToTop';
import * as Sentry from '@sentry/react'
import ReactGA from 'react-ga';

// initialize Google analytics tracker
ReactGA.initialize('UA-175285078-1');
ReactGA.pageview(window.location.pathname + window.location.search);

// configure Sentry
Sentry.init({dsn: "https://dfab5d84bc634b25b05d59f01e70ac84@o425886.ingest.sentry.io/5366589"});

initHotjar();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
