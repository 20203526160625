import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';
import { PUBLIC_FOLDER } from '../../Constant';
import Button from '../Button';
import Navbar from '../NavBar';
import styles from './ImagePage.module.css';
import useDefaultMedia from '../../hooks/useDefaultMedia';

const cx = classNames.bind(styles);

// @grant window.close
function ImagePage({
  title, description, imgSrc,
  primaryButtonText, primaryButtonOnclick,
  secondaryButtonText, secondaryButtonAction,
  navbar,
}) {
  const isWide = useDefaultMedia();
  return (
    <>
      {isWide && navbar && <Navbar />}
      <div className={cx('container')}>
        <div className={cx('main')}>
          <h1 style={{ backgroundImage: `url(${PUBLIC_FOLDER}/img/rsz_brush.png)` }}>
            {title}
          </h1>
          <img alt="page" src={imgSrc} />
          <p>{description}</p>
          <div className={cx('btn-container')}>
            {secondaryButtonText && (
            <Button type="button" color="secondary" onClick={secondaryButtonAction}>
              {secondaryButtonText}
            </Button>
            )}
            <Button type="button" color="primary" onClick={primaryButtonOnclick}>
              {primaryButtonText}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

ImagePage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  primaryButtonOnclick: PropTypes.func,
  primaryButtonText: PropTypes.string.isRequired,
  secondaryButtonAction: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  navbar: PropTypes.bool,
};

ImagePage.defaultProps = {
  primaryButtonOnclick: null,
  secondaryButtonAction: null,
  secondaryButtonText: null,
  navbar: true,
};

export default ImagePage;
