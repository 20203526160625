import React from 'react';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router';
import styles from './NoUserFound.module.css';
import { PUBLIC_FOLDER, AUTH_URL } from '../../Constant';
import Button from '../../components/Button';
import ImagePage from '../../components/ImagePage';

const cx = classNames.bind(styles);

function NoUserFound() {
  const history = useHistory();

  const onPrimaryButtonClick = () => history.push('/skills-mapping/screening');
  return (
    <ImagePage
      title="Map your skills first."
      imgSrc={`${PUBLIC_FOLDER}/img/no_user_found.png`}
      description={`We could not find an account on our service matching your LinkedIn profile.${'\n'}
        Please map your skills first.`}
      primaryButtonText="Start"
      primaryButtonOnclick={onPrimaryButtonClick}
    />
  );
}

export default NoUserFound;
