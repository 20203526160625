import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Card.module.css';

const cx = classNames.bind(styles);

function Card({
  children, className, overflow, style,
}) {
  return (
    <div className={cx('card', { 'overflow-auto': overflow }, className)} style={style}>{children}</div>
  );
}

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  overflow: PropTypes.bool,
};

Card.defaultProps = {
  className: '',
  overflow: false,
};

export default Card;
