import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { CSSTransition } from 'react-transition-group';
import Button from '../../components/Button/Button';
import styles from './LevelsInfoModal.module.css';
import LevelsInfo from './LevelsInfo';

const modalRoot = document.getElementById('modal');
const cx = classNames.bind(styles);

function LevelsInfoModal({ open, onClose }) {
  const nodeRef = useRef(null);

  const handleChildClick = (e) => {
    e.stopPropagation();
  };
  const handleClose = () => {
    onClose();
  };

  return (
    <CSSTransition
      in={open}
      unmountOnExit
      classNames={{
        enter: cx('slide-up-enter'),
        enterActive: cx('slide-up-enter-active'),
        exit: cx('slide-up-exit'),
        exitActive: cx('slide-up-exit-active'),
      }}
      timeout={500}
      nodeRef={nodeRef}
    >
      <div className={cx('modal')} onClick={handleClose} role="none">
        <div
          ref={nodeRef}
          className={cx('modal-content')}
          role="none"
          onClick={handleChildClick}
        >
          <LevelsInfo />
          <div className={cx('continue-button')}>
            <Button type="button" onClick={handleClose}>
              Continue
            </Button>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

LevelsInfoModal.defaultProps = {
  open: false,
  onClose: null,
};

LevelsInfoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ({ onClose, open }) => createPortal(<LevelsInfoModal onClose={onClose} open={open} />, modalRoot);
