import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Copy from 'react-copy-to-clipboard';

import Button from '../Button';
import styles from './CopyToClipboard.module.css';

const cx = classNames.bind(styles);

function CopyToClipboard({ text }) {
  return (
    <div className={cx('container')}>
      <Copy text={text} options={{ format: 'text/plain' }}>
        <Button className={cx('copy-button')}>
          Copy
        </Button>
      </Copy>
    </div>
  );
}

CopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CopyToClipboard;
