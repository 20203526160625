import React, { useState, useContext } from 'react';
import classNames from 'classnames/bind';
import { useHistory, useLocation } from 'react-router';
import { FaArrowDown } from 'react-icons/fa';
import styles from './PeerReviewFeedbacks.module.css';
import BlackCard from './BlackCard';
import { PUBLIC_FOLDER, PEER_LEVELS, SCOPE_OF_WORK } from '../../Constant';
import FeedBackSelect from './FeedBackSelect';
import Button from '../../components/Button';
import StepBackButton from './StepBackButton';
import NavBar from '../../components/NavBar';
import { QuizzContext } from '../../contexts/QuizzContext';
import useDefaultMedia from '../../hooks/useDefaultMedia';
import SaveButton from './SaveButton';

const cx = classNames.bind(styles);

function PeerReviewFeedbacks() {
  const isWide = useDefaultMedia();
  const history = useHistory();
  const { name, skills, uuid } = useLocation().state;
  const {
    strengths,
    setStrenghts,
    improvementAreas,
    setImprovementAreas,
    scopeOfWork,
    setScopeOfWork,
    peerSeniorityLevel,
    setPeerSeniorityLevel,
    userSeniorityLevel,
    setUserSeniorityLevel,
    anecdote,
    setAnecdote,
  } = useContext(QuizzContext);

  const handleStrenghtsChange = (event) => setStrenghts(event.target.value);

  const handleImprovementAreasChange = (event) => setImprovementAreas(event.target.value);

  const handleAnecdoteChange = (event) => setAnecdote(event.target.value);

  const goToNextStep = () => {
    history.push('step-4', {
      name,
      skills,
      uuid,
      strengths,
      improvementAreas,
      anecdote,
      context: {
        scopeOfWork,
        peerSeniorityLevel,
        userSeniorityLevel,
      },
    });
  };

  return (
    <>
      {isWide && <NavBar />}
      <main className={cx('main')}>
        <div className={cx('header-btn')}>
          <StepBackButton step={3} totalSteps={4} />
          <SaveButton />
        </div>
        <h1 className={cx('title')}>
          <FaArrowDown />
          Feedback and advices
        </h1>
        <BlackCard
          cardIndex={1}
          cardLength={3}
          title="Strengths"
          description={`Key capabilities, differentiators. What makes ${name} unique?`}
          imgSrc={`${PUBLIC_FOLDER}/img/07.png`}
        />
        <div className={cx('textarea-container')}>
          <textarea
            value={strengths}
            onChange={handleStrenghtsChange}
            placeholder={`I found ${name}'s sharp when it came to...`}
          />
        </div>
        <BlackCard
          cardIndex={2}
          cardLength={3}
          title="Development areas"
          description={`What are ${name}'s weaknesses? How might we reduce or eliminate them?`}
          imgSrc={`${PUBLIC_FOLDER}/img/08.png`}
        />
        <div className={cx('textarea-container')}>
          <textarea
            value={improvementAreas}
            onChange={handleImprovementAreasChange}
            placeholder="Referring to weaknesses, I remember that..."
          />
        </div>
        <BlackCard
          cardIndex={3}
          cardLength={3}
          title="Context of collaboration"
          imgSrc={`${PUBLIC_FOLDER}/img/09.png`}
        />
        <FeedBackSelect
          title="1. Scope of work"
          description="What was your scope of work when you worked together?"
          options={SCOPE_OF_WORK}
          onSelect={setScopeOfWork}
          selected={scopeOfWork}
          multipleSelect
        />
        <FeedBackSelect
          title="2. Your seniority level"
          description="What was your seniority level when you worked together?"
          options={PEER_LEVELS}
          onSelect={setPeerSeniorityLevel}
          selected={peerSeniorityLevel}
        />
        <FeedBackSelect
          title={`3. ${name}’s seniority level`}
          description={`What was ${name}'s seniority level when you worked together.`}
          options={PEER_LEVELS}
          onSelect={setUserSeniorityLevel}
          selected={userSeniorityLevel}
        />
        <div className={cx('textarea-container')}>
          <h3>
            4. The Future
            {' '}
            {name}
          </h3>
          <p>
            What would
            {' '}
            {name}
            &apos;s career look like 5 years from now?
          </p>
          <textarea
            value={anecdote}
            onChange={handleAnecdoteChange}
            placeholder={` In 5 years, I see ${name}...`}
          />
        </div>
        <div className={cx('button-container')}>
          <Button className={cx('btn')} type="button" onClick={goToNextStep}>
            {isWide ? 'Review before submit' : 'Next'}
          </Button>
        </div>
      </main>
    </>
  );
}

export default PeerReviewFeedbacks;
