import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import ContentEditable from 'react-contenteditable';
import styles from './UserProfile.module.css';
import EditingContext from './EditingContext';

const cx = classNames.bind(styles);

function Editable({
  user, className, value, placeholder,
}) {
  const { editing, setEditing } = useContext(EditingContext);

  return (
    <ContentEditable
      className={cx('editable', className)}
      placeholder={placeholder}
      html={editing === null ? (user?.[value] || '') : editing?.[value]}
      disabled={editing === null}
      onChange={(evt) => setEditing({ ...editing, [value]: evt.target.value })}
      onPaste={(evt) => {
        evt.preventDefault();
        setEditing({ ...editing, [value]: (evt.originalEvent || evt).clipboardData.getData('text/plain') });
      }}
    />
  );
}

Editable.propTypes = {
  value: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

Editable.defaultProps = {
  placeholder: 'Unknown',
  className: '',
};

export default Editable;
