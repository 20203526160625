import React, { useState } from 'react';
import {
  useHistory, useLocation,
} from 'react-router-dom';
import classNames from 'classnames/bind';
import { FaArrowDown } from 'react-icons/fa';
import Button from '../../components/Button';

import NavBar from '../../components/NavBar';
import styles from './Screening.module.css';
import StepBackButton from './StepBackButton';
import useDefaultMedia from '../../hooks/useDefaultMedia';

const cx = classNames.bind(styles);

function Screening({}) {
  const history = useHistory();
  const { name } = useLocation().state;

  const isWide = useDefaultMedia();
  const [workTogether, setWorkTogether] = useState(null);
  const [familiarity, setFamiliarity] = useState(null);

  const redirect = () => {
    if (workTogether === null || familiarity === null) {
      return;
    }
    if (familiarity === 0) {
      history.push('not-familiar', { name });
      return;
    }
    if (workTogether === false) {
      history.push('not-witness', { name });
      return;
    }
    history.push('step-2', { name });
  };

  return (

    <div className={cx('container')} role="none">
      {isWide && <NavBar />}
      <StepBackButton step={1} totalSteps={4} />
      <div className={cx('main')}>
        <div className={cx('main-header')}>
          <FaArrowDown size={20} />
          <h2 className={cx('title')}>
            A bit about yourself
          </h2>
        </div>
        <div className={cx('card')}>
          <h3 className={cx('chart-title')}>
            1. Have you observed
            {' '}
            {name}
            &apos;s UX/UI skills in action?
          </h3>
          <div className={cx('btn-group')} data-toggle="buttons">
            <label htmlFor="option1" className={cx({ checked: workTogether === true }, 'btn')}>
              <input className={cx('hidden')} type="radio" name="options" id="option1" checked={workTogether === true} onClick={() => setWorkTogether(true)} />
              Yes, I have.
            </label>
            <label htmlFor="option2" className={cx({ checked: workTogether === false }, 'btn')}>
              <input className={cx('hidden')} type="radio" name="options" id="option2" checked={workTogether === false} onClick={() => setWorkTogether(false)} />
              No, never.
            </label>
          </div>
        </div>
        <div className={cx('card')}>
          <h3 className={cx('chart-title')}>2. Are you familiar with the field of UX and UI?</h3>
          <div className={cx('btn-group')} data-toggle="buttons">
            <label htmlFor="familiarity-1" className={cx({ checked: familiarity === 2 }, 'btn')}>
              <input className={cx('hidden')} type="radio" name="familiarity" id="familiarity-1" checked={familiarity === 2} onClick={() => setFamiliarity(2)} />
              I am a UX/UI professional.
            </label>
            <label htmlFor="familiarity-2" className={cx({ checked: familiarity === 1 }, 'btn')}>
              <input className={cx('hidden')} type="radio" name="familiarity" id="familiarity-2" checked={familiarity === 1} onClick={() => setFamiliarity(1)} />
              I work with UX/UI professionals.
            </label>
            <label htmlFor="familiarity-3" className={cx({ checked: familiarity === 0 }, 'btn')}>
              <input className={cx('hidden')} type="radio" name="familiarity" id="familiarity-3" checked={familiarity === 0} onClick={() => setFamiliarity(0)} />
              I don’t know what UX/UI is.
            </label>
          </div>
        </div>
        <div className={cx('nextButton')}>
          <Button type="button" onClick={redirect} disabled={workTogether === null || workTogether === null}>Next</Button>
        </div>
      </div>
    </div>
  );
}

Screening.propTypes = {
};

export default Screening;
