import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import style from './Loader.module.css';

const cx = classNames.bind(style);

const Loader = ({
}) => (
  <div className={cx('spinner')}>
    <div className={cx('cube1')} />
    <div className={cx('cube2')} />
    <div className={cx('cube3')} />
    <div className={cx('cube4')} />
  </div>
);

export default Loader;
