import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useHistory, useLocation } from 'react-router';
import styles from './PeerReviewResult.module.css';
import PeerReportDetail from '../../components/PeerReportDetail';
import Button from '../../components/Button';
import {
  getAverageLevelByMacroSkillForPeerReview,
} from '../../utils/skills';
import { API_URL } from '../../Constant';
import StepBackButton from './StepBackButton';
import authFetch from '../../utils/authFetch';
import NavBar from '../../components/NavBar';
import useDefaultMedia from '../../hooks/useDefaultMedia';

const cx = classNames.bind(styles);

function PeerReviewResult() {
  const isWide = useDefaultMedia();
  const history = useHistory();

  const {
    name,
    skills,
    uuid,
    strengths,
    improvementAreas,
    anecdote,
    context,
  } = useLocation().state;
  const { scopeOfWork, peerSeniorityLevel, userSeniorityLevel } = context;

  const [hash, setHash] = useState();
  useEffect(() => {
    fetch(`${API_URL}/peer-review?key=${uuid}`).then((res) => res.json())
      .then((data) => {
        setHash(data.hash);
      });
  }, []);

  const [macroSkills, setMacroSkills] = useState([]);
  useEffect(() => {
    if (skills && !isWide) {
      setMacroSkills(getAverageLevelByMacroSkillForPeerReview(skills, true));
    }
  }, [skills]);

  const series = [{
    name: 'Skills preview',
    data: isWide ? skills.map((el) => (el.level ? el.level : null)) : macroSkills.map((el) => el.averageLevel),
  }];

  const categories = isWide ? skills.map((el) => el.discipline) : macroSkills.map((el) => el.macroSkill);

  const savePeerReview = async () => {
    try {
      const response = await authFetch(`${API_URL}/peer-review`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          skills, uuid, strengths, improvementAreas, anecdote, context, hash,
        }),
      });

      // TODO: Issue #49: Handle errors and show something to the user
      if (response.ok) {
        history.push('thank-you');
      } else {
        // response status is 400 or 409
        history.replace('/');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>

      {isWide && <NavBar />}
      <main className={cx('main')}>
        <StepBackButton step={4} totalSteps={4} />
        <PeerReportDetail
          categories={categories}
          series={series}
          title={`What ${name} will receive`}
          strengths={strengths}
          improvementAreas={improvementAreas}
          scopeOfWork={scopeOfWork}
          peerSeniorityLevel={peerSeniorityLevel}
          userSeniorityLevel={userSeniorityLevel}
          anecdote={anecdote}
          name={name}
        />
        <div className={cx('button-container')}>
          <Button className={cx('btn')} type="submit" onClick={savePeerReview}>
            Submit
          </Button>
        </div>
      </main>
    </>
  );
}

export default PeerReviewResult;
