import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import classNames from 'classnames/bind';
import { MdTimer } from 'react-icons/md';
import { API_URL, PUBLIC_FOLDER } from '../../Constant';
import Button from '../../components/Button';
import styles from './PeerReview.module.css';
import authFetch from '../../utils/authFetch';
import NavBar from '../../components/NavBar';
import useDefaultMedia from '../../hooks/useDefaultMedia';

const cx = classNames.bind(styles);

function PeerReview() {
  const history = useHistory();
  const { uuid } = useParams();
  const [name, setName] = useState('');
  const isWide = useDefaultMedia();

  const resumePeerReview = (firstname) => {
    const savedPeerReview = localStorage.getItem(uuid);

    if (savedPeerReview) {
      const { step } = JSON.parse(savedPeerReview);

      if (step) {
        history.push(`${history.location.pathname}/${step}`, { name: firstname });
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      const result = await authFetch(`${API_URL}/users/${uuid}/firstname`);
      if (result.status !== 201) {
        history.push('/peer-review/account-deleted');
      }
      const jsonData = await result.json();

      const firstname = jsonData?.firstname || 'NONAME';

      resumePeerReview(firstname);
      setName(firstname);
    }
    fetchData();
  }, []);

  const goToProcess = () => history.push(`${uuid}/process`, { name });

  return (
    <>
      {isWide && <NavBar />}
      <div className={cx('main')}>
        <div className={cx('container')}>
          {isWide && <img src={`${PUBLIC_FOLDER}/img/peer_review_home.png`} alt="Peer review" />}
          <div className={cx('Text')}>
            <h1
              className={cx('title')}
              style={{ backgroundImage: `url(${PUBLIC_FOLDER}/img/brush.png)` }}
            >
              Welcome to
              {` ${name}`}
              &apos;s Peer review
            </h1>
            <div>
              <MdTimer size={20} />
              <p className={cx('timeText')}>7-8 min</p>
            </div>
            <div className={cx('content')}>
              <p>No sign-up is required.</p>
              <p>
                Your review will be sent anonymously. The report will be private
                to
                {' '}
                {name}
                .
              </p>
              <p>Link is valid 7 days. You can save your progress.</p>
            </div>
          </div>
          <div className={cx('stepper')}>
            <div className={cx('yellowDot')} />
            <div className={cx('greyDot')} />
          </div>
          <Button className={cx('btn')} type="button" onClick={goToProcess}>
            The Process
          </Button>
        </div>
      </div>
    </>
  );
}

export default PeerReview;
