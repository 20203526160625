import React from 'react';
import PropTypes from 'prop-types';
import Ripples from 'react-ripples';
import classNames from 'classnames/bind';
import style from './Button.module.css';

const cx = classNames.bind(style);

const Button = ({
  children, type, className, onClick, color, disabled,
}) => (
  <div className={cx(className, color, 'buttonContainer', { disabled })}>
    {/* color from primary/secondary to opacity */}
    <Ripples color={color === 'primary' ? 'rgba(0, 0, 0, .3)' : 'rgba(255, 236, 96, .3)'}>
      {/* eslint-disable-next-line react/button-has-type */}
      <button className={cx(color, 'button')} type={type} onClick={onClick}>
        {children}
      </button>
    </Ripples>
  </div>
);

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  className: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
};

Button.defaultProps = {
  disabled: false,
  type: 'button',
  className: '',
  onClick: undefined,
  color: 'primary',
};

export default Button;
