import React from 'react';
import { FiBarChart2 } from 'react-icons/fi';
import classNames from 'classnames/bind';
import styles from './LevelsInfo.module.css';
import { PUBLIC_FOLDER } from '../../Constant';
import useDefaultMedia from '../../hooks/useDefaultMedia';

const cx = classNames.bind(styles);

// TODO: Issue #50 - Change years of experience as they seems incorrect
const levels = [
  {
    name: 'Don\'t know or not sure',
    description: 'Leave unanswered and move to next skill.',
  },
  {
    name: 'Novice',
    description: 'Have notions, but never applied to a project.',
    experience: '0-1 year',
  },
  {
    name: 'Junior',
    description: 'A demonstrated ability to handle with guidance.',
    experience: '1-3 years',
  },
  {
    name: 'Senior',
    description:
      'A demonstrated ability to handle unsupervised, on easy & medium cases.',
    experience: '3-5 years',
  },
  {
    name: 'Lead',
    description:
      'A demonstrated ability to handle complex cases unsupervised and/or to supervise and mentor people from your team.',
    experience: '5-7 years',
  },
  {
    name: 'Expert',
    description:
      'A demonstrated ability to work with executives. To coach or teach people outside your organization.',
    experience: '7-10 years',
  },
  {
    name: 'Master',
    description:
      'Recognized as a reference in your industry. Influencer, book writer, industry shaper.',
    experience: '+10 years',
  },
];

const MobileHeader = () => (
  <div className={cx('level-header')}>
    <FiBarChart2 className={cx('bar-chart-icon')} />
    <h1>What are these levels?</h1>
  </div>
);

const DesktopHeader = () => (
  <div className={cx('header-container')}>
    <div className={cx('level-header')}>
      <FiBarChart2 className={cx('bar-chart-icon')} />
      <h1>Levels of proficiency</h1>
    </div>
    <p>
      Review the details below before proceeding.
    </p>
  </div>
);

function LevelsInfo() {
  const isWide = useDefaultMedia();

  return (
    <>
      {
        isWide ? <DesktopHeader /> : <MobileHeader />
      }
      <ul className={cx('level-list')}>
        {levels.map((level) => (
          <li key={level.name} className={cx('level-item')}>
            <div className={cx('level-indicator')} />
            <div>
              <div className={cx('level-title')}>
                <span>{level.name}</span>
                {level.experience && (
                  <span className={cx('level-badge')}>{level.experience}</span>
                )}
              </div>
              <div className={cx('level-description')}>{level.description}</div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}

export default LevelsInfo;
