import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { CSSTransition } from 'react-transition-group';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import styles from './PeerReviewDetailsModal.module.css';
import PeerReportDetail from '../../components/PeerReportDetail';

const modalRoot = document.getElementById('modal');
const cx = classNames.bind(styles);

function PeerReviewDetailsModal({
  open,
  onClose,
  peerReviews,
  userSkills,
  date,
  strengths,
  improvementAreas,
  peerSeniorityLevel,
  userSeniorityLevel,
  scopeOfWork,
  anecdote,
}) {
  const nodeRef = useRef(null);

  const handleChildClick = (e) => {
    e.stopPropagation();
  };
  const handleClose = () => {
    onClose();
  };

  const filterLevels = (skill) => {
    const level = Object.values(skill)[0];
    return level > 0 ? level : null;
  };

  const series = [
    {
      name: 'You',
      data: userSkills.map(filterLevels),
    },
    {
      name: 'Peer review',
      data: peerReviews.map(filterLevels),
    },
  ];

  const categories = peerReviews.map((item) => Object.keys(item)[0]);

  return (
    <CSSTransition
      in={open}
      unmountOnExit
      classNames={{
        enter: cx('slide-up-enter'),
        enterActive: cx('slide-up-enter-active'),
        exit: cx('slide-up-exit'),
        exitActive: cx('slide-up-exit-active'),
      }}
      timeout={500}
      nodeRef={nodeRef}
    >
      <div className={cx('modal')} onClick={handleClose} role="none">
        <div
          ref={nodeRef}
          className={cx('modal-content')}
          role="none"
          onClick={handleChildClick}
        >
          <div className={cx('modal-header')}>
            <button
              type="button"
              className={cx('modal-back-button')}
              onClick={handleClose}
            >
              <MdKeyboardArrowLeft size={24} />
              <p>Back</p>
            </button>
          </div>
          <PeerReportDetail
            categories={categories}
            series={series}
            strengths={strengths}
            improvementAreas={improvementAreas}
            peerSeniorityLevel={peerSeniorityLevel}
            userSeniorityLevel={userSeniorityLevel}
            scopeOfWork={scopeOfWork}
            anecdote={anecdote}
            title={(
              <>
                Peer report details
                {' '}
                <span className={cx('date')}>{date}</span>
              </>
            )}
          />
        </div>
      </div>
    </CSSTransition>
  );
}

PeerReviewDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  peerReviews: PropTypes.arrayOf(PropTypes.object).isRequired,
  userSkills: PropTypes.arrayOf(PropTypes.object).isRequired,
  date: PropTypes.string.isRequired,
  peerSeniorityLevel: PropTypes.number,
  userSeniorityLevel: PropTypes.number,
  strengths: PropTypes.string,
  anecdote: PropTypes.string,
  improvementAreas: PropTypes.string,
  scopeOfWork: PropTypes.arrayOf(PropTypes.number),
};

PeerReviewDetailsModal.defaultProps = {
  strengths: '',
  improvementAreas: '',
  anecdote: '',
  scopeOfWork: [],
  peerSeniorityLevel: undefined,
  userSeniorityLevel: undefined,
};

export default ({
  onClose,
  open,
  peerReviews,
  userSkills,
  date,
  strengths,
  improvementAreas,
  peerSeniorityLevel,
  userSeniorityLevel,
  scopeOfWork,
  anecdote,
}) => createPortal(
  <PeerReviewDetailsModal
    onClose={onClose}
    open={open}
    userSkills={userSkills}
    peerReviews={peerReviews}
    date={date}
    strengths={strengths}
    improvementAreas={improvementAreas}
    peerSeniorityLevel={peerSeniorityLevel}
    userSeniorityLevel={userSeniorityLevel}
    scopeOfWork={scopeOfWork}
    anecdote={anecdote}
  />,
  modalRoot,
);
