import React from 'react';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import classNames from 'classnames/bind';
import { useLocation } from 'react-router';
import styles from './SkillsMappingResult.module.css';
import YourResult from './YourResult';
import TheWorld from './TheWorld';
import BackButton from '../../components/BackButton';
import NavBar from '../../components/NavBar';
import useDefaultMedia from '../../hooks/useDefaultMedia';

const cx = classNames.bind(styles);

function SkillsMappingResult() {
  const { result } = useLocation().state;
  const isWide = useDefaultMedia();

  const mobileResult = (
    <>
      <h1 className={cx('result-title')}>Result</h1>
      <Tabs>
        <TabList className={cx('tab-list')}>
          <Tab className={cx('tab')} selectedClassName={cx('tab-active')}>
            You
          </Tab>
          <Tab className={cx('tab')} selectedClassName={cx('tab-active')}>
            You and world
          </Tab>
        </TabList>
        <TabPanel>
          <YourResult result={result} />
        </TabPanel>
        <TabPanel>
          <TheWorld result={result} />
        </TabPanel>
      </Tabs>
    </>
  );

  const desktopResult = (
    <div className={cx('grid-container')}>
      <YourResult result={result} />
      <TheWorld result={result} />
    </div>
  );

  return (
    <>
      {isWide && <NavBar />}
      <div className={cx('main')}>
        <BackButton>Back</BackButton>
        {isWide ? desktopResult : mobileResult}
      </div>
    </>
  );
}

export default SkillsMappingResult;
