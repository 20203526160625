import React from 'react';
import PropTypes from 'prop-types';
import { RiArrowLeftSLine } from 'react-icons/ri';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router';
import styles from './BackButton.module.css';

const cx = classNames.bind(styles);

function BackButton({ children, onClick, className }) {
  const history = useHistory();

  return (
    <button
      type="button"
      className={cx('back-button', className)}
      onClick={onClick || history.goBack}
    >
      <RiArrowLeftSLine />
      {children}
    </button>
  );
}

BackButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

BackButton.defaultProps = {
  onClick: undefined,
  className: '',
};

export default BackButton;
