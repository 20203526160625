import React, { useState, useEffect, useCallback } from 'react';
import style from './ScrollProgressBar.module.css';

const ScrollProgressBar = () => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const handleScroll = useCallback((event) => {
    const scroll = (event.srcElement.documentElement.scrollTop
        / (document.body.scrollHeight - window.innerHeight));

    const scrollPercentage = Math.min(100, Math.round(scroll * 100));
    setScrollProgress(scrollPercentage);
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      style={{ width: `${scrollProgress}%` }}
      className={style.progressBar}
    />
  );
};

export default ScrollProgressBar;
