import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import ImagePage from '../../components/ImagePage';
import BackButton from '../../components/BackButton';
import { PUBLIC_FOLDER } from '../../Constant';
import NavBar from '../../components/NavBar';
import styles from './Save.module.css';
import { QuizzContext } from '../../contexts/QuizzContext';

function Save() {
  const SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000;
  const quizz = useContext(QuizzContext);
  const { uuid } = useParams();

  const saveToLocalStorage = () => {
    localStorage.setItem(
      uuid,
      JSON.stringify({ expire: Date.now() + SEVEN_DAYS, quizz }),
    );
    toast.success('Saved', {
      position: 'bottom-center',
    });
  };

  return (
    <>
      <NavBar />
      <BackButton className={styles.back}>Back</BackButton>
      <ImagePage
        navbar={false}
        title="Finish later"
        description="You can reopen the link from the same device/browser during 7 days. No sign-up required."
        imgSrc={`${PUBLIC_FOLDER}/img/save.png`}
        primaryButtonText="Save"
        primaryButtonOnclick={saveToLocalStorage}
      />
    </>
  );
}

export default Save;
