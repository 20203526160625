import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import SkillsMappingScreening from './pages/SkillsMapping/Screening';
import SkillsMappingInvalid from './pages/SkillsMapping/Invalid';
import SkillsMapping from './pages/SkillsMapping';
import SkillsMappingResult from './pages/SkillsMappingResult';
import Profile from './pages/Profile';
import PeerReview from './pages/PeerReview';
import PeerReviewQuizz from './pages/PeerReview/PeerReviewQuizz';
import ThankYou from './pages/PeerReview/ThankYou';
import PeerReviewResult from './pages/PeerReview/PeerReviewResult';
import Screening from './pages/PeerReview/Screening';
import Process from './pages/PeerReview/Process';
import PeerReviewFeedbacks from './pages/PeerReview/PeerReviewFeedbacks';
import Invalid from './pages/PeerReview/Invalid';
import Signup from './pages/SignUp';
import QuizzContextProvider from './contexts/QuizzContext';
import SignUpDeclined from './pages/SignUpDeclined';
import NoUserFound from './pages/NoUserFound';
import Settings from './pages/Settings';
import AuthContextProvider from './contexts/AuthContext';
import LegalMentions from './pages/LegalMentions';
import Save from './pages/PeerReview/Save';
import PeerDeleted from './pages/PeerDeleted';

import clearExpiredItems from './utils/localStorage';
import Error from './pages/Error';

function App() {
  useEffect(clearExpiredItems, []);

  return (
    <div className="App">
      <AuthContextProvider>
        <Switch>
          <Route exact path={['/', '/home']}>
            <Home />
          </Route>
          <Route exact path="/sign-up" component={Signup} />
          <Route exact path="/sign-up/declined" component={SignUpDeclined} />
          <Route exact path="/no-user-found" component={NoUserFound} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/privacy-policy" component={LegalMentions} />
          <Route exact path="/term-of-use" component={LegalMentions} />
          <Route exact path="/impressum" component={LegalMentions} />
          <Route
            path="/skills-mapping"
            render={({ match: { path } }) => (
              <QuizzContextProvider>
                <Route
                  exact
                  path={`${path}/screening`}
                  component={SkillsMappingScreening}
                />
                <Route
                  exact
                  path={`${path}/invalid`}
                  component={SkillsMappingInvalid}
                />
                <Route exact path={`${path}/`} component={SkillsMapping} />
                <Route
                  exact
                  path={`${path}/update/:uuid`}
                  component={() => <SkillsMapping update />}
                />
                <Route
                  exact
                  path={`${path}/result`}
                  component={SkillsMappingResult}
                />
              </QuizzContextProvider>
            )}
          />
          <Route exact path="/profile/:key" component={Profile} />
          <Route
            exact
            path="/peer-review/account-deleted"
            component={PeerDeleted}
          />
          <Route
            path="/peer-review/:uuid"
            render={({ match: { path } }) => (
              <QuizzContextProvider>
                <Route exact path={`${path}/`} component={PeerReview} />
                <Route exact path={`${path}/process`} component={Process} />
                <Route exact path={`${path}/step-1`} component={Screening} />
                <Route exact path={`${path}/save`} component={Save} />
                <Route
                  exact
                  path={`${path}/step-2`}
                  component={PeerReviewQuizz}
                />
                <Route
                  exact
                  path={`${path}/step-3`}
                  component={PeerReviewFeedbacks}
                />
                <Route
                  exact
                  path={`${path}/step-4`}
                  component={PeerReviewResult}
                />
                <Route exact path={`${path}/thank-you`} component={ThankYou} />

                <Route
                  exact
                  path={`${path}/not-familiar`}
                  component={() => <Invalid notKnowledgeable />}
                />
                <Route
                  exact
                  path={`${path}/not-witness`}
                  component={Invalid}
                />
              </QuizzContextProvider>
            )}
          />
          <Route exact path="/something-went-wrong" component={Error} />
          <Route>
            <div> 404 </div>
          </Route>
        </Switch>
      </AuthContextProvider>
      <ToastContainer />
    </div>
  );
}

export default App;
