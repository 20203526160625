import React from 'react';
import PropTypes from 'prop-types';
import ApexChart from 'react-apexcharts';
import classNames from 'classnames/bind';
import styles from './Chart.module.css';
import { LEVELS } from '../../Constant';

const cx = classNames.bind(styles);

const chartOptions = {
  chart: {
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  legend: {
    position: 'top',
    horizontalAlign: 'right',
  },
  colors: ['#FFEC60', '#292929'],
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '25%',
      endingShape: 'rounded',
      startingShape: 'rounded',
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    labels: {
      hideOverlappingLabels: false,
      rotate: 0,
      show: true,
      trim: true,
      style: {
        cssClass: cx('xaxis-label'),
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    min: 0,
    max: 6,
    show: false,
    tickAmount: LEVELS.length + 1,
    forceNiceScale: true,
    labels: {
      show: false,
      formatter: (value) => (value !== 0 ? LEVELS[Math.round(value) - 1] : 'N/A'),
    },
  },
  grid: {
    show: true,
    borderColor: '#DEDEDE',
    padding: {
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
};

const chartOptionsDetails = {
  ...chartOptions,
  yaxis: {
    min: 0,
    max: 6,
    tickAmount: LEVELS.length + 1,
    forceNiceScale: true,
    labels: {
      show: true,
      align: 'left',
      formatter: (value) => (value !== 0 ? LEVELS[Math.round(value) - 1] : 'N/A'),
      style: {
        cssClass: cx('yaxis-label'),
      },
      offsetX: -5,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '45%',
      endingShape: 'rounded',
      startingShape: 'rounded',
    },
  },
  xaxis: {
    labels: {
      show: true,
      hideOverlappingLabels: false,
      trim: false,
      style: {
        cssClass: cx('xaxis-label'),
      },
      offsetY: 10,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  grid: {
    show: true,
    borderColor: '#DEDEDE',
    padding: {
      left: 26,
      bottom: 10,
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },
};

const Chart = ({
  series, categories, details, height, title, columnWidth, legend, optimized
}) => {
  const usedOptions = details ? chartOptionsDetails : chartOptions;
  const options = {
    ...usedOptions,
    xaxis: {
      ...usedOptions.xaxis,
      categories,
    },
  };
  if (!legend) {
    options.legend = { show: false };
  }
  if (optimized) {
    options.chart.animations = { enabled: false };
    options.markers = { size: 0 };
    options.dataLabels = { enabled: false };
  }
  if (title) {
    options.title = {
      text: title,
      align: 'center',
      style: {
        fontSize: '17px',
        fontFamily: 'Konnect-Medium',
        color: '#000000',
      },
    };
  }

  if (columnWidth) {
    options.plotOptions.bar.columnWidth = columnWidth;
  }
  return (
    <ApexChart
      type="bar"
      options={options}
      series={series}
      height={height}
    />
  );
};

Chart.propTypes = {
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.array.isRequired,
  columnWidth: PropTypes.string,
  details: PropTypes.bool,
  height: PropTypes.string,
  title: PropTypes.string,
  legend: PropTypes.bool,
  optimized: PropTypes.bool,
};

Chart.defaultProps = {
  columnWidth: null,
  details: false,
  height: '200px',
  title: null,
  legend: true,
  optimized: false,
};

export default Chart;
