import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames/bind';
import styles from './InputText.module.css';

const cx = className.bind(styles);

function InputText({ placeholder, onChange, value }) {
  return (
    <input className={cx('input')} type="text" placeholder={placeholder} onChange={onChange} value={value} />
  );
}

InputText.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InputText;
