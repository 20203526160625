import React from 'react';
import PropTypes from 'prop-types';
import style from './BlackCard.module.css';

function BlackCard({ cardIndex, cardLength, title, subtitle, description, imgSrc }) {
  return (
    <div className={style.card}>
      <div className={style.content}>
        <div className={style.cardIndex}>
          {`Part ${cardIndex} of ${cardLength}`}
        </div>
        <h1 className={style.cardTitle}>{title}</h1>
        <h2 className={style.cardSubTitle}>{subtitle}</h2>
        <span className={style.cardDescription}>{description}</span>
        <br />
      </div>
      <img className={style.image} alt={`${title}`} src={imgSrc} />
    </div>
  );
}

BlackCard.propTypes = {
  cardIndex: PropTypes.number.isRequired,
  cardLength: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
};

BlackCard.defaultProps = {
  subtitle: '',
};

export default BlackCard;
