/* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import style from './Question.module.css';
import { LEVELS } from '../../Constant';

const cx = classNames.bind(style);

const Radio = ({
  value, onChange, selected,
}) => {
  const isChecked = value === selected;
  return (
    <div
      className={cx({ radioButtonSelected: isChecked }, 'radioButton')}
      role="radio"
      aria-checked={isChecked}
      onClick={() => onChange(value)}
    >
      <span className={cx({ radioButtonTextHidden: !isChecked })}>
        {LEVELS[value - 1]}
      </span>
    </div>
  );
};

Radio.defaultProps = {
  selected: null,
};

Radio.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.number,
};

export default Radio;
