import React from 'react';
import classNames from 'classnames/bind';
import Card from '../../components/Card';
import styles from './LegalMentions.module.css';

const cx = classNames.bind(styles);

function PrivacyPolicy() {
  return (
    <Card>
      <div className={cx('terms-content')}>
        <h2>Privacy Policy</h2>
        <p>Effective date: August 9th 2020</p>
        <h3>Introduction</h3>
        <p>Welcome to Whatuxru.com</p>
        <p>
          Whatuxru.com (“us”, “we”, or “our”) operates
          {' '}
          <a href="https://whatuxru.com" target="_blank" rel="noreferrer">
            https://whatuxru.com
          </a>
          {' '}
          (hereinafter referred to as “Service”).
        </p>
        <p>
          Our Privacy Policy governs your visit to
          {' '}
          <a href="https://whatuxru.com" target="_blank" rel="noreferrer">
            https://whatuxru.com
          </a>
          , and explains how we collect, safeguard and disclose information that
          results from your use of our Service.
        </p>
        <p>
          We use your data to provide and improve Service. By using Service, you
          agree to the collection and use of information in accordance with this
          policy. Unless otherwise defined in this Privacy Policy, the terms
          used in this Privacy Policy have the same meanings as in our Terms and
          Conditions.
        </p>
        <p>
          Our Terms and Conditions (“Terms”) govern all use of our Service and
          together with the Privacy Policy constitutes your agreement with us
          (“agreement”).
        </p>
        <h3>Definitions</h3>
        <p>
          SERVICE means the
          {' '}
          <a href="https://whatuxru.com" target="_blank" rel="noreferrer">
            https://whatuxru.com
          </a>
          {' '}
          website operated by Whatuxru.com
        </p>
        <p>
          PERSONAL DATA means data about a living individual who can be
          identified from those data (or from those and other information either
          in our possession or likely to come into our possession).
        </p>
        <p>
          USAGE DATA is data collected automatically either generated by the use
          of Service or from Service infrastructure itself (for example, the
          duration of a page visit).
        </p>
        <p>
          COOKIES are small files stored on your device (computer or mobile
          device).
        </p>
        <p>
          DATA CONTROLLER means a natural or legal person who (either alone or
          jointly or in common with other persons) determines the purposes for
          which and the manner in which any personal data are, or are to be,
          processed. For the purpose of this Privacy Policy, we are a Data
          Controller of your data.
        </p>
        <p>
          DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal
          person who processes the data on behalf of the Data Controller. We may
          use the services of various Service Providers in order to process your
          data more effectively.
        </p>
        <p>
          DATA SUBJECT is any living individual who is the subject of Personal
          Data.
        </p>
        <p>
          THE USER is the individual using our Service. The User corresponds to
          the Data Subject, who is the subject of Personal Data.
        </p>
        <h3>Information Collection and Use</h3>
        <p>
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>
        <h3>Types of Data Collected</h3>
        <h5>Personal Data</h5>
        <p>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you (“Personal Data”). Personally identifiable information
          may include, but is not limited to:
        </p>
        <ol type="a">
          <li>Email address: pulled via LinkedIn login API.</li>
          <li>First name and last name: pulled via LinkedIn login API.</li>
          <li>Cookies and Usage Data</li>
        </ol>
        <p>
          We will use your Personal Data to send you via email the result of
          your peer reviews if you decide to use this feature. We may use your
          Personal Data to contact you with newsletters, marketing or
          promotional materials and other information that may be of interest to
          you. You may opt out of receiving any, or all, of these communications
          from us by following the unsubscribe link or by emailing at
          {' '}
          <a href="mailto:hello@whatuxru.com">hello@whatuxru.com</a>
        </p>
        <h5>Usage Data</h5>
        <p>
          We may also collect information that your browser sends whenever you
          visit our Service or when you access Service by or through a mobile
          device (“Usage Data”).
        </p>
        <p>
          This Usage Data may include information such as your computer&apos;s
          Internet Protocol address (e.g. IP address), browser type, browser
          version, the pages of our Service that you visit, the time and date of
          your visit, the time spent on those pages, unique device identifiers
          and other diagnostic data.
        </p>
        <p>
          When you access Service with a mobile device, this Usage Data may
          include information such as the type of mobile device you use, your
          mobile device unique ID, the IP address of your mobile device, your
          mobile operating system, the type of mobile Internet browser you use,
          unique device identifiers and other diagnostic data.
        </p>
        <h5>Tracking Cookies Data</h5>
        <p>
          We use cookies and similar tracking technologies to track the activity
          on our Service and we hold certain information.
        </p>
        <p>
          Cookies are files with a small amount of data which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device. Other tracking technologies are
          also used such as beacons, tags and scripts to collect and track
          information and to improve and analyze our Service.
        </p>
        <p>
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if you do not accept cookies,
          you may not be able to use some portions of our Service.
        </p>
        <p>Examples of Cookies we use:</p>
        <ol type="a">
          <li>
            Session Cookies: We use Session Cookies to operate our Service.
          </li>
          <li>
            Preference Cookies: We use Preference Cookies to remember your
            preferences and various settings.
          </li>
          <li>
            Security Cookies: We use Security Cookies for security purposes.
          </li>
        </ol>
        <h3>Sign-up & Sign-in with LinkedIn</h3>
        <p>
          Whatuxru.com uses the LinkedIn login API to enable users to
          sign-up & sign-in to our service in one click. For more information
          about the LinkedIn login Api, visit :
          {' '}
          <a href="https://www.linkedin.com/developers/" target="_blank" rel="noreferrer">
            https://www.linkedin.com/developers/
          </a>.
        </p>
        <p>
        The sign-up and sign-in to our service is for now only manageable through
        LinkedIn. It is a way for us to secure 1 profile on our service for 1
        profile on LinkedIn. This will help us provide you more accurate market
        data and insights in phase 2.
        </p>
        <ol type="a">
          <li>We do not store any of your LinkedIn credentials;</li>
          <li>LinkedIn handles the whole authentication process;</li>
          <li>
            You can remove access you've given to our service by turning on/off
            data sharing from the Privacy section of your LinkedIn Settings page.
            Link to this page :
            {' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/psettings/permitted-services"
            >
              https://www.linkedin.com/psettings/permitted-services
            </a>
            . You can also delete your Whatuxru.com account from the Settings page.
          </li>
          <li>
            LinkedIn provides us your basic profile information (name, surname,
            email). We use these to populate your profile information.
          </li>
        </ol>
        <h3>Use of Data</h3>
        <p>Whatuxru.com uses the collected data for various purposes:</p>
        <ol type="a">
          <li>to provide and maintain our Service;</li>
          <li>to notify you about changes to our Service;</li>
          <li>
            to allow you to participate in interactive features of our Service
            when you choose to do so;
          </li>
          <li>to provide customer support;</li>
          <li>
            to gather analysis or valuable information so that we can improve
            our Service;
          </li>
          <li>to monitor the usage of our Service;</li>
          <li>to detect, prevent and address technical issues;</li>
          <li>to fulfill any other purpose for which you provide it;</li>
          <li>
            to carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection;
          </li>
          <li>
            to provide you with notices about your account and/or subscription,
            including expiration and renewal notices, email-instructions, etc.;
          </li>
          <li>
            to provide you with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless you have opted not to receive such information;
          </li>
          <li>
            in any other way we may describe when you provide the information;
          </li>
          <li>for any other purpose with your consent.</li>
        </ol>
        <h3>Retention of Data</h3>
        <p>
          We will retain your Personal Data only for as long as is necessary for
          the purposes set out in this Privacy Policy. We will retain and use
          your Personal Data to the extent necessary to comply with our legal
          obligations (for example, if we are required to retain your data to
          comply with applicable laws), resolve disputes, and enforce our legal
          agreements and policies.
        </p>
        <p>
          We will also retain Usage Data for internal analysis purposes. Usage
          Data is generally retained for a shorter period, except when this data
          is used to strengthen the security or to improve the functionality of
          our Service, or we are legally obligated to retain this data for
          longer time periods.
        </p>
        <h3>Transfer of Data</h3>
        <p>
          Your information, including Personal Data, may be transferred to – and
          maintained on – computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ from those of your jurisdiction.
        </p>
        <p>
          If you are located outside United States and choose to provide
          information to us, please note that we transfer the data, including
          Personal Data, to United States and process it there.
        </p>
        <p>
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
        </p>
        <p>
          Whatuxru.com will take all the steps reasonably necessary to
          ensure that your data is treated securely and in accordance with this
          Privacy Policy and no transfer of your Personal Data will take place
          to an organisation or a country unless there are adequate controls in
          place including the security of your data and other personal
          information.
        </p>
        <h3>Disclosure of Data</h3>
        <p>
          We may disclose personal information that we collect, or you provide:
        </p>
        <ol type="a">
          <li>
            Disclosure for Law Enforcement.
            <p>
              Under certain circumstances, we may be required to disclose your
              Personal Data if required to do so by law or in response to valid
              requests by public authorities.
            </p>
          </li>
          <li>
            Business Transaction.
            <p>
              If we or our subsidiaries are involved in a merger, acquisition or
              asset sale, your Personal Data may be transferred.
            </p>
          </li>
          <li>
            Other cases. We may disclose your information also:
            <ol type="i">
              <li>
                to contractors, service providers, and other third parties we
                use to support our business (e.g. recruiters, learning
                institutions…)
              </li>
              <li>to fulfill the purpose for which you provide it;</li>
              <li>
                for any other purpose disclosed by us when you provide the
                information;
              </li>
            </ol>
          </li>
        </ol>
        <h3>Security of Data</h3>
        <p>
          The security of your data is important to us but remember that no
          method of transmission over the Internet or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </p>
        <h3>
          Your Data Protection Rights Under General Data Protection Regulation
          (GDPR)
        </h3>
        <p>
          If you are a resident of the European Union (EU) and European Economic
          Area (EEA), you have certain data protection rights, covered by GDPR.
          – See more at
          {' '}
          <a
            href="https://eur-lex.europa.eu/eli/reg/2016/679/oj"
            target="_blank"
            rel="noreferrer"
          >
            https://eur-lex.europa.eu/eli/reg/2016/679/oj
          </a>
          {' '}
        </p>
        <p>
          We aim to take reasonable steps to allow you to correct, amend,
          delete, or limit the use of your Personal Data.
        </p>
        <p>
          If you wish to be informed what Personal Data we hold about you and if
          you want it to be removed from our systems, please email us at
          {' '}
          <a href="mailto:hello@whatuxru.com">hello@whatuxru.com</a>
        </p>
        <p>
          In certain circumstances, you have the following data protection
          rights:
        </p>
        <ol type="a">
          <li>
            the right to access, update or to delete the information we have on
            you;
          </li>
          <li>
            the right of rectification. You have the right to have your
            information rectified if that information is inaccurate or
            incomplete;
          </li>
          <li>
            the right to object. You have the right to object to our processing
            of your Personal Data;
          </li>
          <li>
            the right of restriction. You have the right to request that we
            restrict the processing of your personal information;
          </li>
          <li>
            the right to data portability. You have the right to be provided
            with a copy of your Personal Data in a structured, machine-readable
            and commonly used format;
          </li>
          <li>
            the right to withdraw consent. You also have the right to withdraw
            your consent at any time where we rely on your consent to process
            your personal information;
          </li>
        </ol>
        <p>
          Please note that we may ask you to verify your identity before
          responding to such requests. Please note, we may not be able to
          provide Service without some necessary data.
        </p>
        <p>
          You have the right to complain to a Data Protection Authority about
          our collection and use of your Personal Data. For more information,
          please contact your local data protection authority in the European
          Economic Area (EEA).
        </p>
        <h3>Service Providers</h3>
        <p>
          We may employ third party companies and individuals to facilitate our
          Service (“Service Providers”), provide Service on our behalf, perform
          Service-related services or assist us in analysing how our Service is
          used.
        </p>
        <p>
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.
        </p>
        <h3>Analytics</h3>
        <p>
          We may use third-party Service Providers to monitor and analyze the
          use of our Service.
        </p>
        <h5>Google Analytics</h5>
        <p>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our Service. This data is shared with
          other Google services. Google may use the collected data to
          contextualise and personalise the ads of its own advertising network.
        </p>
        <p>
          For more information on the privacy practices of Google, please visit
          the Google Privacy Terms web page:
          {' '}
          <a
            href="https://policies.google.com/privacy?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            https://policies.google.com/privacy?hl=en
          </a>
        </p>
        <p>
          We also encourage you to review the Google&apos;s policy for
          safeguarding your data:
          {' '}
          <a
            href="https://support.google.com/analytics/answer/6004245"
            target="_blank"
            rel="noreferrer"
          >
            https://support.google.com/analytics/answer/6004245
          </a>
        </p>
        <h5>Hotjar</h5>
        <p>
          Hotjar is a behavior analytics company that analyses website use,
          providing feedback through tools such as heatmaps, session recordings,
          and surveys. It works with web analytics tools such as Google
          Analytics to offer an insight into how people are navigating websites,
          and how their customer experience can be improved.
        </p>
        <p>
          For more information on the privacy practices of Hotjar, please visit
          the HotjarPrivacy Terms web page:
          {' '}
          <a
            href="https://www.hotjar.com/legal/policies/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.hotjar.com/legal/policies/privacy/
          </a>
        </p>
        <h3>CI/CD Tools</h3>
        <p>
          We may use third-party Service Providers to automate the development
          process of our Service.
        </p>
        <h5>GitLab</h5>
        <p>GitLab is a registered trademark of GitLab, Inc</p>
        <p>
          <a
            href="https://about.gitlab.com/company/history/"
            target="_blank"
            rel="noreferrer"
          >
            GitLab started
          </a>
          {' '}
          as an open source project to help teams collaborate on software
          development.
        </p>
        <p>
          For more information on what data GitLab collects for what purpose and
          how the protection of the data is ensured, please visit GitLab Privacy
          Policy page:
          {' '}
          <a
            href="https://about.gitlab.com/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            https://about.gitlab.com/privacy/
          </a>
          .
        </p>
        <h3>Payments</h3>
        <p>
          We may provide paid products and/or services within Service. In that
          case, we use third-party services for payment processing (e.g. payment
          processors).
        </p>
        <p>
          We will not store or collect your payment card details. That
          information is provided directly to our third-party payment processors
          whose use of your personal information is governed by their Privacy
          Policy. These payment processors adhere to the standards set by
          PCI-DSS as managed by the PCI Security Standards Council, which is a
          joint effort of brands like Visa, Mastercard, American Express and
          Discover. PCI-DSS requirements help ensure the secure handling of
          payment information.
        </p>
        <p>The payment processors we might work with are:</p>
        <p>
          Stripe: Their Privacy Policy can be viewed at:
          {' '}
          <a
            href="https://stripe.com/us/privacy"
            target="_blank"
            rel="noreferrer"
          >
            https://stripe.com/us/privacy
          </a>
        </p>
        <h3>Links to Other Sites</h3>
        <p>
          Our Service may contain links to other sites that are not operated by
          us. If you click a third party link, you will be directed to that
          third party&apos;s site. We strongly advise you to review the Privacy
          Policy of every site you visit. We have no control over and assume no
          responsibility for the content, privacy policies or practices of any
          third party sites or services.
        </p>
        <h3>Children&apos;s Privacy</h3>
        <p>
          Our Services are not intended for use by children under the age of 13
          (“Children”).
        </p>
        <p>
          We do not knowingly collect personally identifiable information from
          Children under 13. If you become aware that a Child has provided us
          with Personal Data, please contact us. If we become aware that we have
          collected Personal Data from Children without verification of parental
          consent, we take steps to remove that information from our servers.
        </p>
        <h3>Changes to This Privacy Policy</h3>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p>
          We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update “effective
          date” at the top of this Privacy Policy
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have any questions about this Privacy Policy, please contact
          us:
        </p>
        <p>
          By email:
          {' '}
          <a href="mailto:hello@whatuxru.com">hello@whatuxru.com</a>
        </p>
      </div>
    </Card>
  );
}

export default PrivacyPolicy;
