import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './SlidingCheckbox.module.css';

const cx = classNames.bind(styles);

function SlidingCheckbox({ name, checked, onChange }) {
  const inputId = `checkbox_${name}`;
  return (
    <label htmlFor={inputId} className={cx('switch')}>
      <input id={inputId} name={name} type="checkbox" defaultChecked={checked} onChange={onChange} />
      <span className={cx('slider')} />
    </label>
  );
}

SlidingCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

SlidingCheckbox.defaultProps = {
  checked: false,
};

export default SlidingCheckbox;
