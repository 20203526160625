import React from 'react';
import classNames from 'classnames/bind';
import Card from '../../components/Card';
import styles from './LegalMentions.module.css';

const cx = classNames.bind(styles);

function Impressum() {
  return (
    <Card>
      <div className={cx('terms-content')}>
        <h2>Statement of ownership of Whatuxru.com</h2>
        <p>Effective date: August 9th 2020</p>
        <h3>The company</h3>
        <p className={cx('company')}>UX Skills s.a.s</p>
        <p>
          Les Aqueducs B3 , 535 Route des Lucioles, 06560 Valbonne (Sophia-Antipolis), France.
          <br />
          VAT number: IT07347120961
        </p>
        <p>
          Company registered to the Chamber of Commerce of Antibes, REA 1952415.
          <br />
          Kbis number : 887 508 455
          <br />
          Legal Representative:
          {' '}
          <a
            href="https://www.linkedin.com/in/antoineyng/"
            target="_blank"
            rel="noreferrer"
          >
            Antoine Yoeung
          </a>
          {' '}

        </p>
        <p>
          Paid up share capital: 1000 Euros.
          <br />
          Email:{' '}<a href="mailto:hello@whatuxru.com">hello@whatuxru.com</a>
          <br />
          Join our
          {' '}
          <a
            href="https://www.linkedin.com/groups/10545334/"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn group
          </a>
          {' '}.
        </p>
      </div>
    </Card>
  );
}

export default Impressum;
