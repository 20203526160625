import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import { FaArrowDown } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import styles from './Settings.module.css';
import { AuthContext } from '../../contexts/AuthContext';
import AuthSettings from './AuthSettings';
import Card from '../../components/Card';
import ScrollToTop from '../../components/ScrollToTop';
import BackButton from '../../components/BackButton';
import { ReactComponent as WemanityLogo } from '../../assets/svg/wemanity.svg';

const cx = classNames.bind(styles);

function Settings() {
  const isAuth = useContext(AuthContext);

  return (
    <>
      <ScrollToTop />
      <NavBar />
      <BackButton>Back</BackButton>
      <div className={cx('content')}>
        <h1>
          <FaArrowDown />
          Settings
        </h1>
        {isAuth && <AuthSettings />}
        <h2>Legal</h2>
        <Link to="/term-of-use">
          <Card>
            <h3>
              Terms and conditions
              <IoIosArrowForward />
            </h3>
          </Card>
        </Link>
        <Link to="/privacy-policy">
          <Card>
            <h3>
              Privacy policy
              <IoIosArrowForward />
            </h3>
          </Card>
        </Link>
        <Link to="/impressum">
          <Card>
            <h3>
              Impressum
              <IoIosArrowForward />
            </h3>
          </Card>
        </Link>
        <h2>Partner</h2>
        <a href="https://wemanity.com/" target="_blank" rel="noreferrer">
          <Card>
            <div className={cx('flex-container', 'partner')}>
              <p>
                This MVP was created with our partner Wemanity, digital
                acceleration agency.
              </p>
              <WemanityLogo />
            </div>
          </Card>
        </a>
        <h2>Contact</h2>
        <a href="https://yng.surveysparrow.com/s/contact/tt-71ac9f" target="_blank" rel="noreferrer">
          <Card>
            <h3>
              Improvements, bugs or commercial inquiries.
              <IoIosArrowForward />
            </h3>
          </Card>
        </a>
      </div>
    </>
  );
}

export default Settings;
