import React from 'react';
import { RiArrowLeftSLine } from 'react-icons/ri';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import styles from './StepBackButton.module.css';

const cx = classNames.bind(styles);

function StepBackButton({ step, totalSteps }) {
  const history = useHistory();

  return (
    <button
      type="button"
      className={cx('back-button')}
      onClick={history.goBack}
    >
      <RiArrowLeftSLine />
      Step
      {' '}
      {step}
      {' '}
      of
      {' '}
      {totalSteps}
    </button>
  );
}

StepBackButton.propTypes = {
  step: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default StepBackButton;
