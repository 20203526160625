import React from 'react';
import PropTypes from 'prop-types';
import { FaArrowDown } from 'react-icons/fa';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import classNames from 'classnames/bind';
import Chart from '../Chart';
import { SCOPE_OF_WORK, PEER_LEVELS } from '../../Constant';
import styles from './PeerReportDetail.module.css';

const cx = classNames.bind(styles);

const checkEmptyItem = (item) => (item && item.length > 0 ? item : <i>Your peer has not provided feedback.</i>);

const displayScopeOfWork = (scope) => {
  if (Object.prototype.toString.call(scope) === '[object String]') {
    return scope;
  }
  return SCOPE_OF_WORK[scope];
};

function PeerReportDetail({
  categories,
  series,
  title,
  strengths,
  improvementAreas,
  peerSeniorityLevel,
  userSeniorityLevel,
  scopeOfWork,
  anecdote,
  name,
}) {
  return (
    <div className={cx('main')}>
      <div className={cx('main-header')}>
        <FaArrowDown size={20} />
        <h2 className={cx('title')}>{title}</h2>
      </div>
      <div className={cx('card')}>
        <h3 className={cx('chart-title')}>Skill blueprint</h3>
        <div className={cx('chart-container')}>
          <Chart
            categories={categories}
            series={series}
            details
            height="100%"
          />
        </div>
      </div>
      <Tabs>
        <TabList className={cx('tab-list')}>
          <Tab className={cx('tab')} selectedClassName={cx('tab-active')}>
            Strengths
          </Tab>
          <Tab className={cx('tab')} selectedClassName={cx('tab-active')}>
            Development areas
          </Tab>
          <Tab className={cx('tab')} selectedClassName={cx('tab-active')}>
            Context
          </Tab>
        </TabList>
        <TabPanel>
          <div className={cx('card')}>
            <p className={cx('card-content')}>{checkEmptyItem(strengths)}</p>
          </div>
        </TabPanel>
        <TabPanel>
          <div className={cx('card')}>
            <p className={cx('card-content')}>
              {checkEmptyItem(improvementAreas)}
            </p>
          </div>
        </TabPanel>
        <TabPanel>
          <div className={cx('card')}>
            <div className={cx('card-content')}>
              <h3>Scope of work of your reviewer</h3>
              <ul>
                {checkEmptyItem(
                  scopeOfWork.map((item) => (
                    <li key={item}>{displayScopeOfWork(item)}</li>
                  )),
                )}
              </ul>
              <h3>Seniority level of your reviewer</h3>
              <p>{checkEmptyItem(PEER_LEVELS[peerSeniorityLevel])}</p>
              <h3>Your seniority level at that time</h3>
              <p>{checkEmptyItem(PEER_LEVELS[userSeniorityLevel])}</p>
              <h3>
                The future
                {' '}
                {name}
              </h3>
              <p>{checkEmptyItem(anecdote)}</p>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
}

PeerReportDetail.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  peerSeniorityLevel: PropTypes.number,
  userSeniorityLevel: PropTypes.number,
  strengths: PropTypes.string,
  improvementAreas: PropTypes.string,
  anecdote: PropTypes.string,
  scopeOfWork: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  name: PropTypes.string,
};

PeerReportDetail.defaultProps = {
  anecdote: '',
  strengths: '',
  improvementAreas: '',
  scopeOfWork: [],
  peerSeniorityLevel: undefined,
  userSeniorityLevel: undefined,
  name: 'you',
};

export default PeerReportDetail;
