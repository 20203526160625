import React, {
  useState, useEffect, useContext, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { FaArrowDown } from 'react-icons/fa';
import { useParams } from 'react-router';
import style from './Quizz.module.css';
import Button from '../../components/Button/Button';
import Question from './Question';
import MacroSkillCard from './MacroSkillCard';
import { API_URL } from '../../Constant';
import LevelsInfoModalButton from './LevelsInfoModalButton';
import authFetch from '../../utils/authFetch';
import Loader from '../../components/Loader';
import { QuizzContext } from '../../contexts/QuizzContext';
import LevelsInfo from './LevelsInfo';
import Card from '../../components/Card';
import useDefaultMedia from '../../hooks/useDefaultMedia';

const Quizz = ({
  onSave, buttonText, title, update,
}) => {
  const [data, setData] = useState([]);
  const { levels, setLevels } = useContext(QuizzContext);
  const [loading, setLoading] = useState(true);
  const isWide = useDefaultMedia();
  const { uuid } = useParams();
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await authFetch(`${API_URL}/skills`);
      const jsonData = await result.json();
      setLoading(false);
      if (levels.length === 0) setLevels(jsonData.map((el) => el.Skills).flat());
      setData(jsonData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (update && data.length > 0) {
      (async () => {
        const response = await authFetch(`${API_URL}/users/${uuid}/skills/detail`);

        if (response.ok) {
          const res = await response.json();
          const currentSkills = Object.assign(...res);
          setLevels(levels.map((item) => ({
            ...item,
            level: currentSkills[item.discipline],
          })));
        }
      })();
    }
  }, [update, data]);

  const setLevel = (discipline, level) => {
    setLevels(
      levels.map((item) => {
        if (item.id !== discipline) {
          return item;
        }
        return {
          ...item,
          level: item.level === level ? 0 : level,
        };
      }),
    );
  };

  const handleScroll = useCallback((event) => {
    const scroll = event.srcElement.documentElement.scrollTop;
    setScrollProgress(scroll);
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (loading) {
    return (<Loader />);
  } if (data.length >= 1) {
    return (
      <div className={style['quizz-container']}>
        <div className={style.main}>
          <div className={style.container}>
            <FaArrowDown />
            <h1 className={style.title}>{title}</h1>
          </div>
          {data.map((macro, macroIndex) => (
            <>
              <MacroSkillCard
                macroIndex={macroIndex + 1}
                macroLength={data.length}
                title={macro.name}
                description={macro.description}
              />
              {macro.Skills.map((question, questionIndex) => (
                <Question
                  key={question.id}
                  title={`${questionIndex + 1}. ${question.discipline}`}
                  deliverables={question.deliverables}
                  selected={levels.find((el) => el.id === question.id).level}
                  setAnswer={(level) => setLevel(question.id, level)}
                />
              ))}
            </>
          ))}
          <Button className={style['see-my-result']} onClick={() => onSave(levels)} type="submit">
            { update ? 'Update skills mapping' : buttonText}
          </Button>
        </div>
        {!isWide ? (
          <LevelsInfoModalButton />
        ) : (
          <div className={style['card-container']}>
            <Card
              className={style['card-infos']}
              style={{
                top: scrollProgress < 58 ? `${100 + 58 - scrollProgress}px` : '100px',
                height: scrollProgress < 58 ? `calc(100vh - 140px - 58px + ${scrollProgress}px)` : 'calc(100vh - 140px)',
              }}
            >
              <LevelsInfo />
            </Card>
          </div>
        )}
      </div>
    );
  }
  return (
    <div className={style.loadingError}>
      Error: Couldn&apos;t load the questions
    </div>
  );
};

Quizz.defaultProps = {
  buttonText: 'See my results',
  update: false,
};

Quizz.propTypes = {
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  update: PropTypes.bool,
};

export default Quizz;
