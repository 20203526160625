function groupByMacroSkill(skills) {
  return skills.reduce((result, { level, Skill }) => {
    // Create new MacroSkill group
    if (!result[Skill.MacroSkill.name]) {
      result[Skill.MacroSkill.name] = {
        skills: [],
      };
    }

    result[Skill.MacroSkill.name].skills.push({ level, discipline: Skill.discipline });

    return result;
  }, {});
}

function calculateAverageLevel(skills) {
  if (skills.length === 0) {
    return 0;
  }
  const sumOfLevels = skills.reduce((sum, skill) => sum + (skill.level || 0), 0);
  return Math.round(sumOfLevels / skills.length);
}

function calculateAverageLevelOmittingZeros(skills) {
  if (skills.length === 0) {
    return 0;
  }
  const skillLengthWithoutZeros = skills.reduce((sum, skill) => (skill.level > 0 ? sum + 1 : sum), 0);
  const sumOfLevels = skills.reduce(
    (sum, skill) => sum + (skill.level || 0),
    0,
  );
  return Math.round(sumOfLevels / skillLengthWithoutZeros);
}

export function getAverageLevelByMacroSkill(result) {
  const macroSkills = groupByMacroSkill(result);
  return Object.keys(macroSkills).map((macroSkill) => {
    const { skills } = macroSkills[macroSkill];
    const averageLevel = calculateAverageLevel(skills);
    return {
      macroSkill,
      skills,
      averageLevel,
    };
  });
}

export function getMacroAverage(worldResults) {
  const result = Array(6).fill(0);
  const nbOfMicros = Array.from(new Set(worldResults)).length;
  worldResults.forEach((skillResult) => {
    const { level, levelCount } = skillResult;

    if (level !== 0) { // Ignore blank answers
      result[level - 1] += levelCount / nbOfMicros;
    }
  });
  return result;
}

export function getMacroAverageInPercent(worldResults) {
  const levels = getMacroAverage(worldResults);

  const sumOfLevels = levels.reduce((sum, level) => sum + level);

  const result = levels.map((level) => (level / sumOfLevels) * 100);

  return result;
}

function groupByMacroSkillForPeerReview(skills) {
  return skills.reduce((result, skill) => {
    // Create new MacroSkill group
    if (!result[skill.MacroSkill.name]) {
      result[skill.MacroSkill.name] = {
        skills: [],
      };
    }

    result[skill.MacroSkill.name].skills.push({
      level: skill.level,
      discipline: skill.discipline,
    });
    console.log('group', result);
    return result;
  }, {});
}

export function getAverageLevelByMacroSkillForPeerReview(result, omitZeros = false) {
  const macroSkills = groupByMacroSkillForPeerReview(result);
  return Object.keys(macroSkills).map((macroSkill) => {
    const { skills } = macroSkills[macroSkill];
    const averageLevel = !omitZeros ? calculateAverageLevel(skills) : calculateAverageLevelOmittingZeros(skills);
    return {
      macroSkill,
      skills,
      averageLevel,
    };
  });
}
