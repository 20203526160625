import React from 'react';
import { useHistory } from 'react-router';
import ImagePage from '../../components/ImagePage';
import { PUBLIC_FOLDER } from '../../Constant';

function Error() {
  const history = useHistory();

  const goToContact = () => history.push('/contact');

  return (
    <ImagePage
      title="Houston we have a bug"
      imgSrc={`${PUBLIC_FOLDER}/img/bug.png`}
      description={`Something went wrong.

      We’ll fix it. Please come back later.
      Apologies for the inconvenience.
      
      If needed, contact us.`}
      primaryButtonText="Contact us"
      primaryButtonOnclick={goToContact}
    />
  );
}

export default Error;
