import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router';
import classNames from 'classnames/bind';
import { API_URL, AUTH_URL } from '../../Constant';
import NavBar from '../../components/NavBar';
import UserProfile from './UserProfile';
import authFetch from '../../utils/authFetch';
import EditableProfile from './EditableProfile';
import PeerReviewTabPanel from './PeerReviewTabPanel';
import styles from './Profile.module.css';
import WideSkillMapping from './WideSkillMapping';
import EditingContext from './EditingContext';
import useDefaultMedia from '../../hooks/useDefaultMedia';
import Button from '../../components/Button';

const cx = classNames.bind(styles);

function Profile() {
  const { key } = useParams();
  const history = useHistory();
  const { editing } = useContext(EditingContext);
  const isWide = useDefaultMedia();

  const [user, setUser] = useState();

  useEffect(() => {
    (async () => {
      const response = await authFetch(`${API_URL}/users/${key}`);
      if (response.ok) {
        const data = await response.json();
        setUser(data);
        if (data.isProfileOwner) history.replace(`${data.key}`);
      } else if (response.status === 401) {
        const redirectConfirmation = window.confirm('🔑 Sign-in to access your profile.');
        if (redirectConfirmation) window.location = `${AUTH_URL}/linkedin/sign-in`;
        else history.replace('/');
      } else {
        history.replace('/');
      }
    })();
  }, []);

  const [userSkills, setUserSkills] = useState([]);
  useEffect(() => {
    if (user) {
      (async () => {
        const response = await authFetch(`${API_URL}/users/${user.key}/skills`);

        if (response.ok) {
          const data = await response.json();

          setUserSkills(data);
        }
      })();
    }
  }, [user]);

  const [userSkillsDetail, setUserSkillsDetail] = useState([]);
  useEffect(() => {
    if (user && isWide) {
      (async () => {
        const response = await authFetch(`${API_URL}/users/${user.key}/skills/detail`);

        if (response.ok) {
          const data = await response.json();

          setUserSkillsDetail(data);
        }
      })();
    }
  }, [user, isWide]);

  const [peerReviews, setPeerReviews] = useState([]);
  useEffect(() => {
    if (user && user.isProfileOwner) {
      (async () => {
        const response = await authFetch(`${API_URL}/peer-review/${user.key}`);

        if (response.ok) {
          const data = await response.json();
          setPeerReviews(data);
        }
      })();
    }
  }, [user]);

  const [peerReviewsDetail, setPeerReviewsDetail] = useState([]);
  useEffect(() => {
    if (user && user.isProfileOwner && isWide) {
      (async () => {
        const response = await authFetch(`${API_URL}/peer-review/${user.key}/detail`);

        if (response.ok) {
          const data = await response.json();
          setPeerReviewsDetail(data);
        }
      })();
    }
  }, [user, isWide]);

  if (!user) return <span>We are loading your information...</span>;

  const render = (children) => (!isWide || editing ? <div className="margin-bottom">{children}</div> : children);

  return render(
    <>
      <NavBar />
      <div className={cx('grid-container')}>
        <div className={cx('tips-tricks')}>
          <a
            href="https://medium.com/whatuxru-com"
            target="_blank"
            rel="noreferrer"
          >
            <span role="img" aria-label="light bulb">
              💡
            </span>
            Tips and Tricks
          </a>
        </div>
        <div className={cx('profile')}>
          {user && user.isProfileOwner ? (
            <EditableProfile
              user={user}
              setUser={setUser}
              userSkills={userSkills}
              peerReviews={peerReviews}
              peerReviewsDetail={peerReviewsDetail}
              userSkillsDetail={userSkillsDetail}
            />
          ) : (
            <UserProfile
              user={user}
              userSkills={userSkills}
              editable={false}
              peerReviews={peerReviews}
            />
          )}
        </div>
        {isWide && (
          <>
            <div
              className={cx('skills')}
            >
              <WideSkillMapping
                user={user}
                userSkills={userSkills}
                peerReviews={peerReviews}
              />
            </div>
            {user && user.isProfileOwner && (
              <div className={cx('peer-reviews')}>
                <PeerReviewTabPanel
                  peerReviews={peerReviews}
                  userSkills={userSkills}
                  peerReviewsDetail={peerReviewsDetail}
                  userSkillsDetail={userSkillsDetail}
                  userKey={user.key}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>,
  );
}

export default Profile;
