/* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import style from './Question.module.css';
import RadioGroup from './RadioGroup';

function Question({
  title, deliverables, setAnswer, selected,
}) {
  return (
    <div className={style.question}>
      <h1 className={style.questionTitle}>{title}</h1>
      <span className={style.questionDeliverables}>
        E.g.
        {' '}
        {deliverables.join(', ')}
        .
      </span>
      <br />
      <RadioGroup onChange={setAnswer} selected={selected} />
    </div>
  );
}

Question.defaultProps = {
  title: 'Title',
  deliverables: ['Deliverable 1', 'Deliverable 2'],
  selected: null,
};

Question.propTypes = {
  title: PropTypes.string,
  deliverables: PropTypes.arrayOf(PropTypes.string),
  setAnswer: PropTypes.func.isRequired,
  selected: PropTypes.number,
};

export default Question;
