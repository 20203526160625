import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import styles from './PeerReviewCard.module.css';
import { PUBLIC_FOLDER } from '../../Constant';
import Button from '../../components/Button';
import useDefaultMedia from '../../hooks/useDefaultMedia';

const cx = classNames.bind(styles);

function PeerReviewCard() {
  const isWide = useDefaultMedia();
  return (
    <div className={cx('card')}>
      <div className={cx('card-content')}>
        <div>
          <h2 className={cx('card-title')}>Save your results</h2>
          <p className={cx('card-description')}>
            Make your Design Passport safe
            {' '}
            <span role="img" aria-label="lock">
              🔐
            </span>
            {' '}
            and update it along your journey.
          </p>
          {isWide && (
            <div className={cx('button-container')}>
              <Link className="no-underline text-centered" to="/sign-up">
                <Button className={cx('button')}>Save now</Button>
              </Link>
            </div>
          )}
        </div>
        <img
          className={cx('card-image')}
          alt="Peer review"
          src={`${PUBLIC_FOLDER}/img/peer_review_card.png`}
        />
      </div>
      {!isWide && (
        <div className={cx('button-container')}>
          <Link className="no-underline text-centered" to="/sign-up">
            <Button className={cx('button')}>Save now</Button>
          </Link>
        </div>
      )}
    </div>
  );
}

export default PeerReviewCard;
