import React, { useEffect } from 'react';
import {
  Link,
} from 'react-router-dom';
import classNames from 'classnames/bind';

import { useHistory } from 'react-router';
import CookieConsent from 'react-cookie-consent';
import { PUBLIC_FOLDER, AUTH_URL } from '../../Constant';
import NavBar from '../../components/NavBar';
import Button from '../../components/Button';
import styles from './Home.module.css';

const cx = classNames.bind(styles);

function Home() {
  const history = useHistory();
  const isloggedIn = async () => {
    const response = await fetch(`${AUTH_URL}/check`, {
      credentials: 'include',
    });
    if (response.ok) {
      history.push('/profile/me');
    }
  };

  useEffect(() => {
    isloggedIn();
  }, []);

  return (
    <div className={cx('main-background')}>
      <div
        className={cx('home')}
        style={{ backgroundImage: `url(${PUBLIC_FOLDER}/img/volcano.png)` }}
      >
        <NavBar />
        <CookieConsent
          location="top"
          buttonText="Accept"
          expires={150}
          overlayStyle={{}}
          buttonClasses={cx('coookie-accept-button')}
          containerClasses={cx('cookie-container')}
          contentClasses={cx('cookie-content')}
          style={{
            background: '#3393F2',
            color: '#FFFFFF',
            fontFamily: 'Konnect-Regular',
            fontSize: '14px',
            textAlign: 'center',
            position: 'relative',
            top: '20px',
          }}
          buttonStyle={{
            background: '#FFFFFF',
            color: '#3393F2',
            fontFamily: 'Konnect-Medium',
            fontSize: '14px',
            borderRadius: '18px',
            padding: '6px 21px',
          }}
        >
          We use
          {' '}
          <Link to="/privacy-policy">Cookies</Link>
          {' '}
          for statistics,
          that’s it.
        </CookieConsent>
        <div className={cx('content')}>
          <h1
            className={cx('title')}
            style={{
              backgroundImage: `url(${PUBLIC_FOLDER}/img/rsz_brush.png)`,
            }}
          >
            Map your UX and UI skills.
          </h1>
          <ul className={cx('list')}>
            <li className={cx('list-element')}>
              <img
                className={cx('')}
                alt="list_bullet"
                src={`${PUBLIC_FOLDER}/img/list_bullet.png`}
              />
              You will see a list of UX & UI skills. Skills are divided into
              deliverables.
            </li>
            <li>
              <img
                alt="list_bullet"
                src={`${PUBLIC_FOLDER}/img/list_bullet.png`}
              />
              Evaluate your proficiency for each skill.
            </li>
            <li>
              <img
                alt="list_bullet"
                src={`${PUBLIC_FOLDER}/img/list_bullet.png`}
              />
              If you do not know or are not sure, leave unanswered and move to
              next skill.
            </li>
          </ul>

          <Link className={cx('link')} to="/skills-mapping/screening">
            <Button className={cx('start-skill-mapping')} color="primary">
              <div className={cx('start-skill-mapping-text')}>
                <span>Start</span>
              </div>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
