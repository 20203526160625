// EditingContext.js
import React from 'react';

const EditingContext = React.createContext({
  editing: null,
  startEditing: () => {},
  setEditing: () => {},
  saveEdit: () => {},
});

export default EditingContext;
