import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import style from './MacroBubbleChart.module.css';
import Tooltip from '../../components/Tooltip';

const cx = classNames.bind(style);

function LevelBubble({ className, level }) {

  return (
    <>
      <Tooltip
        placement="top"
        trigger="click"
        tooltip={`${Math.round(level)}%`}
      >
        <div className={cx('bubble-wrapper')}>
          <div
            style={{ width: `${level}%`, height: `${level}%` }}
            className={className}
          />
        </div>
      </Tooltip>
    </>
  );
}

LevelBubble.propTypes = {
  className: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
};

export default LevelBubble;
