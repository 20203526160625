import React, { useContext } from 'react';
import { useParams, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { QuizzContext } from '../../contexts/QuizzContext';

import styles from './SaveButton.module.css';

const cx = classNames.bind(styles);

function SaveButton() {
  const SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000;
  const quizz = useContext(QuizzContext);
  const { uuid } = useParams();
  const { pathname } = useLocation();

  const saveToLocalStorage = () => {
    localStorage.setItem(
      uuid,
      JSON.stringify({
        expire: Date.now() + SEVEN_DAYS,
        quizz,
        step: pathname.split('/').pop(),
      }),
    );
    toast.success((
      <>
        Saved!
        <br />
        Reopen the link from the same device & browser.
        <br />
        No sign-up required.
      </>), {
      position: 'bottom-center',

    });
  };

  return (
    <button
      type="button"
      className={cx('save-button')}
      onClick={saveToLocalStorage}
    >
      Save
    </button>
  );
}

export default SaveButton;
