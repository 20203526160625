import React from 'react';
import classNames from 'classnames/bind';
import styles from './Signup.module.css';

import useDefaultMedia from '../../hooks/useDefaultMedia';
import { PUBLIC_FOLDER } from '../../Constant';

const cx = classNames.bind(styles);

function Features() {
  const isWide = useDefaultMedia();
  return (
    <div>
      {isWide && <h2 className={cx('list-title')}>Features</h2>}
      <ul className={cx('list', 'linkedin-list')}>
        <li className={cx('list-element')}>
          <img
            className={cx('')}
            alt="list_bullet"
            src={`${PUBLIC_FOLDER}/img/list_bullet.png`}
          />
          <div className={cx('li-text')}>
            <h4 className={cx('title')}>Save & upgrade</h4>
            <p className={cx('subtitle')}>
              Monitor your skills, identify your growth areas.
            </p>
          </div>
        </li>
        <li className={cx('list-element')}>
          <img
            className={cx('')}
            alt="list_bullet"
            src={`${PUBLIC_FOLDER}/img/list_bullet.png`}
          />
          <div className={cx('li-text')}>
            <h4 className={cx('title')}>Collect feedback easily</h4>
            <p className={cx('subtitle')}>
              Share your peer review link and learn from the insights left by
              colleagues or clients.
            </p>
          </div>
        </li>
        <li className={cx('list-element')}>
          <img
            className={cx('')}
            alt="list_bullet"
            src={`${PUBLIC_FOLDER}/img/list_bullet.png`}
          />
          <div className={cx('li-text')}>
            <h4 className={cx('title')}>Share your profile</h4>
            <p className={cx('subtitle')}>
              Share your personal link with anyone. Embed it to your resume and
              LinkedIn.
            </p>
          </div>
        </li>
        <li className={cx('list-element')}>
          <img
            className={cx('')}
            alt="list_bullet"
            src={`${PUBLIC_FOLDER}/img/list_bullet.png`}
          />
          <div className={cx('li-text')}>
            <h4 className={cx('title')}>Phase 2 in progress</h4>
            <p className={cx('subtitle')}>
              Live data, live reports, trainings…
              <span role="img" aria-label="sparkling">
                ✨
              </span>
            </p>
          </div>
          <div className={cx('bullet')}>Soon !</div>
        </li>
      </ul>
    </div>
  );
}

export default Features;
