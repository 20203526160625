import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { MdAdd, MdCheck, MdClear } from 'react-icons/md';
import { FaLink } from 'react-icons/fa';
import ContentEditable from 'react-contenteditable';
import styles from './LinkList.module.css';
import EditingContext from './EditingContext';
import { ReactComponent as Tie } from './tie.svg';
import stripHTML from '../../utils/stripHTML';

const cx = classNames.bind(styles);

const STATIC_LINKS = ['LinkedIn', 'Portfolio', 'Behance'];

function LinkList({ links, onAddClick, isOwner }) {
  const { editing, setEditing } = useContext(EditingContext);

  const [showInput, setShowInput] = useState(false);
  const showNewLinkInput = () => {
    setShowInput(true);
  };

  const onEdit = (evt, index) => {
    const newLinks = [...editing.links];

    // ContentEditable return html as value
    const html = evt.target.value;

    newLinks[index].url = stripHTML(html);
    setEditing({ ...editing, links: newLinks });
  };

  const removeLink = (index) => {
    const newLinks = [...editing.links];
    newLinks.splice(index, 1);
    setEditing({ ...editing, links: newLinks });
  };

  const newLink = (name) => {
    const newLinks = [...editing.links];
    if (name.length > 0) {
      newLinks.push({ name });
      const newEditing = { ...editing, links: newLinks };
      setEditing(newEditing);
    }
  };

  useEffect(() => {
    if (editing) {
      STATIC_LINKS.forEach((item) => {
        if (!editing.links.find((link) => link.name === item)) {
          newLink(item);
        }
      });
    }
  }, [editing]);

  const [newLinkName, setNewLinkName] = useState('');
  const onNewLinkNameChange = (evt) => setNewLinkName(evt.target.value);
  const addNewLink = (evt) => {
    evt.preventDefault();
    newLink(newLinkName);
    setNewLinkName('');
  };

  if (editing === null) {
    return (
      <ul className={cx('list')}>
        {isOwner && (
        <li>
          <button type="button" onClick={onAddClick}>
            <MdAdd />
            <span>Links</span>
          </button>
        </li>
        )}
        {links.map(({ name, url }, index) => (
        // eslint-disable-next-line react/no-array-index-key
          <li key={`${index}`}>
            <a href={url} target="_blank" rel="noreferrer">
              {name}
            </a>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <>
      <h2 className={cx('title')}>
        <FaLink />
        Links
      </h2>
      <div className={cx('card')}>
        {editing.links.map(({ name, url }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={cx('link-container')} key={`${index}`}>
            <p>{name}</p>
            <ContentEditable
              className={cx('editable')}
              placeholder="Https://www..."
              html={
                editing === null
                  ? url || 'Insert URL'
                  : editing.links[index].url || ''
              }
              disabled={editing === null}
              onChange={(evt) => onEdit(evt, index)}
              tagName="p"
            />
            <button
              className={cx('remove-link')}
              type="button"
              onClick={() => removeLink(index)}
            >
              <MdClear />
            </button>
          </div>
        ))}
        {editing && showInput && (
          <form onSubmit={addNewLink}>
            <input
              type="text"
              placeholder="Name"
              value={newLinkName}
              onChange={onNewLinkNameChange}
            />
            <button type="submit">
              <MdCheck />
            </button>
          </form>
        )}
        <button
          className={cx('add-link')}
          type="button"
          onClick={showNewLinkInput}
        >
          + add new link
        </button>
      </div>
    </>
  );
}

LinkList.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAddClick: PropTypes.func.isRequired,
  isOwner: PropTypes.bool,
};
LinkList.defaultProps = {
  isOwner: false,
};

export default LinkList;
