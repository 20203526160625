import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './MacroBubbleChart.module.css';
import { LEVELS } from '../../Constant';
import { getMacroAverageInPercent } from '../../utils/skills';
import LevelBubble from './LevelBubble';

const cx = classNames.bind(styles);

function MacroBubbleChart({
  userAverageLevel,
  macroName,
  macroCount,
  worldResults,
}) {
  const [averageLevels, setAverageLevels] = useState([]);
  useEffect(() => {
    setAverageLevels(getMacroAverageInPercent(worldResults));
  }, [worldResults]);

  const [contextualText, setContextualText] = useState('');
  useEffect(() => {
    const userPositionInWorlResult = Math.floor(averageLevels.slice(userAverageLevel).reduce((a, b) => a + b, 0));
    if (userPositionInWorlResult > 66) {
      setContextualText('High density of peers ahead. Growing this skill might not be a good move.');
    } else if (userPositionInWorlResult > 33) {
      setContextualText('Moderate density of peers ahead. Growing this skill seems a fair move.');
    } else {
      setContextualText('Low density of peers ahead. Growing this skill seems a good move.');
    }
  }, [averageLevels, userAverageLevel]);

  return (
    <div className={cx('container')}>
      <h3 className={cx('macro-title')}>{macroName}</h3>
      <p className={cx('your-level')}>{contextualText}</p>
      <div className={cx('bubble-chart')}>
        <div className={cx('line')} />
        {averageLevels.map((level, index) => (
          <LevelBubble
            key={`${level}${index}`}
            level={level}
            className={cx('bubble', {
              selected: index === Math.round(userAverageLevel) - 1,
            })}
          />
        ))}
      </div>
    </div>
  );
}

MacroBubbleChart.propTypes = {
  userAverageLevel: PropTypes.number.isRequired,
  macroName: PropTypes.string.isRequired,
  macroCount: PropTypes.number.isRequired,
  worldResults: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MacroBubbleChart;
