import React from 'react';
import classNames from 'classnames/bind';
import styles from './Signup.module.css';
import { PUBLIC_FOLDER } from '../../Constant';
import useDefaultMedia from '../../hooks/useDefaultMedia';

const cx = classNames.bind(styles);

function UseCases() {
  const isWide = useDefaultMedia();
  return (
    <div>
      {isWide && <h2 className={cx('list-title')}>Use cases</h2>}
      <ul className={cx('list')}>
        <li className={cx('list-element')}>
          <img
            className={cx('')}
            alt="list_bullet"
            src={`${PUBLIC_FOLDER}/img/list_bullet.png`}
          />
          <div className={cx('li-text')}>
            <h4 className={cx('title')}>Job seekers</h4>
            <p className={cx('subtitle')}>
              Define your UX positioning. Show where your value is. Stand out
              from the herd. Turn interviews into demonstrations.
            </p>
          </div>
        </li>
        <li className={cx('list-element')}>
          <img
            className={cx('')}
            alt="list_bullet"
            src={`${PUBLIC_FOLDER}/img/list_bullet.png`}
          />
          <div className={cx('li-text')}>
            <h4 className={cx('title')}>Recruiters</h4>
            <p className={cx('subtitle')}>
              Request mapping before sharing job descriptions. Decrypt
              candidates strengths in a blink. Ask the right questions. Reduce
              false positives.
            </p>
          </div>
        </li>
        <li className={cx('list-element')}>
          <img
            className={cx('')}
            alt="list_bullet"
            src={`${PUBLIC_FOLDER}/img/list_bullet.png`}
          />
          <div className={cx('li-text')}>
            <h4 className={cx('title')}>Teachers & mentors</h4>
            <p className={cx('subtitle')}>
              Audit students, track progress. Invite students to peer review each
              other. Trigger value added talks from day 1.
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default UseCases;
