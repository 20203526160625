/* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Radio from './Radio';
import style from './Question.module.css';

import { LEVELS } from '../../Constant';

function RadioGroup({ onChange, selected }) {
  return (
    <div className={style.radioGroup}>
      {LEVELS.map((level, i) => (
        <Radio
          key={level}
          value={i + 1}
          selected={selected}
          onChange={(newLevel) => { onChange(newLevel); }}
        />
      ))}
      <div className={style.slider} />
    </div>
  );
}

RadioGroup.defaultProps = {
  selected: null,
};

RadioGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.number,
};

export default RadioGroup;
