import React from 'react';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import styles from './SignUpDeclined.module.css';
import { PUBLIC_FOLDER, AUTH_URL } from '../../Constant';
import ImagePage from '../../components/ImagePage';
import BackButton from '../../components/BackButton';
import useDefaultMedia from '../../hooks/useDefaultMedia';

const cx = classNames.bind(styles);

function SignUpDeclined() {
  const history = useHistory();

  const isWide = useDefaultMedia();
  const onPrimaryButtonClick = () => history.push('/sign-up');
  const onBackButtonClick = () => window.location.assign(`${AUTH_URL}/linkedin/sign-up`);

  return (
    <>

      {!isWide
        && (
          <BackButton className={cx('back-button')} onClick={onBackButtonClick}>
            Back
          </BackButton>
        )}
      <ImagePage
        title="Not keen to sign-up with LinkedIn ?"
        imgSrc={`${PUBLIC_FOLDER}/img/sign_up_declined.png`}
        description={`We understand your concern.${'\n'}
        Please note that we do not store any of your credentials. LinkedIn handles this part.${'\n'}
        Check our ‘Why LinkedIn’ page.`}
        primaryButtonText="Why LinkedIn?"
        primaryButtonOnclick={onPrimaryButtonClick}
      />
    </>
  );
}

export default SignUpDeclined;
