import React from 'react';
import PropTypes from 'prop-types';
import style from './StickyHeader.module.css';
import ScrollProgressBar from '../../components/ScrollProgressBar';

const StickyHeader = ({ who }) => (
  <div className={style.sticky}>
    <div style={{ backgroundColor: 'white' }}>
      <ScrollProgressBar />
      <div className={style.header}>
        <h1 className={style.title}>Step 1 of 2 • Skills mapping</h1>
      </div>
    </div>
  </div>
);

StickyHeader.defaultProps = {
  who: 'someone',
};

StickyHeader.propTypes = {
  who: PropTypes.string,
};

export default StickyHeader;
