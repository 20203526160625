import React, { useState } from 'react';
import {
  useHistory, useLocation,
} from 'react-router-dom';
import classNames from 'classnames/bind';
import { FaArrowDown } from 'react-icons/fa';
import { RiArrowLeftSLine } from 'react-icons/ri';
import Button from '../../components/Button';
import NavBar from '../../components/NavBar';

import styles from './Screening.module.css';
import { PUBLIC_FOLDER } from '../../Constant';
import useDefaultMedia from '../../hooks/useDefaultMedia';

const cx = classNames.bind(styles);

function Screening() {
  const history = useHistory();
  const isWide = useDefaultMedia();

  const [familiarity, setFamiliarity] = useState([]);

  const redirect = () => {
    if (familiarity.includes(0)) {
      history.push('invalid');
      return;
    }
    // if (familiarity.includes(1)) {
    //   history.push('/contact');
    //   return;
    // }
    if (familiarity.length > 0) {
      history.push('/skills-mapping/');
    }
  };

  const handleSelect = (value) => {
    let newFamiliarity;
    if (familiarity.includes(value)) {
      newFamiliarity = familiarity.filter((item) => item !== value);
    } else {
      newFamiliarity = [...familiarity, value];
    }
    setFamiliarity(newFamiliarity);
  };

  return (
    <>
      {isWide ? (
        <NavBar />
      ) : (
        <button
          type="button"
          className={cx('back-button')}
          onClick={history.goBack}
        >
          <RiArrowLeftSLine />
          Back
        </button>
      )}
      <div div className={cx('container')}>
        <div className={cx('main')}>
          {isWide && (
          <img
            src={`${PUBLIC_FOLDER}/img/skills_mapping.png`}
            alt="Skills mapping"
          />
          )}
          <div className={cx('main-header')}>
            <FaArrowDown size={20} />
            <h2
              className={cx('title')}
              style={isWide ? {
                backgroundImage: `url(${PUBLIC_FOLDER}/img/rsz_brush.png)`,
              } : null}
            >
              What is your current situation?
            </h2>
          </div>
          <div className={cx('card')}>
            <div className={cx('btn-group')} data-toggle="buttons">
              <label
                htmlFor="familiarity-1"
                className={cx({ checked: familiarity.includes(3) }, 'btn')}
              >
                <input
                  className={cx('hidden')}
                  type="checkbox"
                  name="familiarity"
                  id="familiarity-1"
                  checked={familiarity.includes(3)}
                  onClick={() => handleSelect(3)}
                />
                I am a UX/UI professional.
              </label>
              <label
                htmlFor="familiarity-2"
                className={cx({ checked: familiarity.includes(2) }, 'btn')}
              >
                <input
                  className={cx('hidden')}
                  type="checkbox"
                  name="familiarity"
                  id="familiarity-2"
                  checked={familiarity.includes(2)}
                  onClick={() => handleSelect(2)}
                />
                I’m transitioning to UX/UI.
              </label>
              <label
                htmlFor="familiarity-3"
                className={cx({ checked: familiarity.includes(1) }, 'btn')}
              >
                <input
                  className={cx('hidden')}
                  type="checkbox"
                  name="familiarity"
                  id="familiarity-3"
                  checked={familiarity.includes(1)}
                  onClick={() => handleSelect(1)}
                />
                I’m a UX/UI recruiter.
              </label>
              <label
                htmlFor="familiarity-4"
                className={cx({ checked: familiarity.includes(0) }, 'btn')}
              >
                <input
                  className={cx('hidden')}
                  type="checkbox"
                  name="familiarity"
                  id="familiarity-4"
                  checked={familiarity.includes(0)}
                  onClick={() => handleSelect(0)}
                />
                I’m not related to UX/UI.
              </label>
            </div>
          </div>
          <div style={{ alignSelf: 'center' }}>
            <Button
              type="button"
              className={cx('start-button')}
              onClick={redirect}
              disabled={familiarity.length === 0}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Screening;
