import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaArrowDown } from 'react-icons/fa';
import classNames from 'classnames/bind';
import Modal from './PeerReviewModal';
import PeerReviewCard from './PeerReviewCard';
import { PUBLIC_FOLDER } from '../../Constant';
import styles from './PeerReviewTabPanel.module.css';
import useDefaultMedia from '../../hooks/useDefaultMedia';
import Button from '../../components/Button';

const cx = classNames.bind(styles);

function PeerReviewTabPanel({
  peerReviews,
  userSkills,
  peerReviewsDetail,
  userSkillsDetail,
  userKey,
}) {
  const isWide = useDefaultMedia();
  const [startPeerReview, setStartPeerReview] = useState(false);

  const handleOpen = () => {
    setStartPeerReview(true);
  };
  const handleClose = () => {
    setStartPeerReview(false);
  };
  const mobileTitle = (
    <>
      <div className={cx('peer-reviews-title')}>
        <FaArrowDown />
        <h3>
          {peerReviews.length}
          {' '}
          peer reviews received
        </h3>
      </div>
      <p className={cx('peer-reviews-title2')}>
        Reviews are private and only you can see them.
      </p>
    </>
  );

  const desktopTitle = (
    <>
      <div className={cx('peer-reviews-title')}>
        <h3>
          Peer reviews (
          {peerReviews.length}
          )
        </h3>
        <p className={cx('peer-reviews-title2')}>
          Reviews are private and only you can see them.
        </p>
      </div>
    </>
  );

  return (
    <>
      {isWide && desktopTitle}
      <div className={cx('list')}>
        <Button
          className={cx('card')}
          onClick={handleOpen}
        >
          <div className={cx('card-content')}>
            <h2 className={cx('card-title')}>Start peer review</h2>
            <img alt="Peer review" src={`${PUBLIC_FOLDER}/img/plus.png`} />
          </div>
          <p>
            Collect feedback like you collect user voices. Implement a
            continuous improvement loop.
          </p>
        </Button>
        {!isWide && mobileTitle}
        {peerReviews.map((item) => (
          <PeerReviewCard
            key={item.createdAt}
            className={cx('card')}
            strengths={item.strengths}
            improvementAreas={item.improvementAreas}
            peerSeniorityLevel={item.context && item.context.peerSeniorityLevel}
            scopeOfWork={item.context && item.context.scopeOfWork}
            userSeniorityLevel={item.context && item.context.userSeniorityLevel}
            anecdote={item.anecdote}
            peerReviews={item.values}
            createdAt={item.createdAt}
            userSkills={userSkills}
            userSkillsDetail={userSkillsDetail}
            peerReviewsDetail={peerReviewsDetail.find(
              (el) => el.createdAt === item.createdAt,
            )?.values}
          />
        ))}
      </div>
      <Modal open={startPeerReview} onClose={handleClose} peerReviewLink={`${window.location.origin}/peer-review/${userKey}`} />
    </>
  );
}

PeerReviewTabPanel.propTypes = {
  peerReviews: PropTypes.arrayOf(PropTypes.object).isRequired,
  userSkills: PropTypes.arrayOf(PropTypes.object).isRequired,
  peerReviewsDetail: PropTypes.arrayOf(PropTypes.object).isRequired,
  userSkillsDetail: PropTypes.arrayOf(PropTypes.object).isRequired,
  userKey: PropTypes.string.isRequired,
};

export default PeerReviewTabPanel;
