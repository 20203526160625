/* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import style from './MacroSkillCard.module.css';
import { PUBLIC_FOLDER } from '../../Constant';

function MacroSkillCard({
  title, description, macroIndex, macroLength,
}) {
  return (
    <div className={style.card}>

      <div>
        <div className={style.cardIndex}>{`Part ${macroIndex} of ${macroLength}`}</div>
        <h1 className={style.cardTitle}>{title}</h1>
        <span className={style.cardDescription}>
          {description}
        </span>
        <br />
      </div>
      <img
        className={style.image}
        alt={`${title}`}
        src={`${PUBLIC_FOLDER}/img/${macroIndex.toString().padStart(2, '0')}.png`}
      />
    </div>
  );
}

MacroSkillCard.defaultProps = {
  title: 'Title',
  description: 'lorem ipsum',
  macroIndex: 0,
  macroLength: 0,
};

MacroSkillCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  macroIndex: PropTypes.number,
  macroLength: PropTypes.number,
};

export default MacroSkillCard;
