import React, { useState, useEffect } from 'react';
import {
  Link,
  useHistory,
} from 'react-router-dom';
import { useLocation } from 'react-router';

import classNames from 'classnames/bind';
import { PUBLIC_FOLDER } from '../../Constant';
import Button from '../../components/Button';

import styles from './PeerReview.module.css';
import NavBar from '../../components/NavBar';
import useDefaultMedia from '../../hooks/useDefaultMedia';

const cx = classNames.bind(styles);

function Process() {
  const history = useHistory();
  const isWide = useDefaultMedia();

  const { name } = useLocation().state;

  return (
    <>
      {isWide && <NavBar />}
      <div className={cx('main')}>
        <div className={cx('container')}>
          <div className={cx('Text')}>
            <h1
              className={cx('title')}
              style={{ backgroundImage: `url(${PUBLIC_FOLDER}/img/brush.png)` }}
            >
              The process
            </h1>
            <div className={cx('content')}>
              <div>
                <h2 className={cx('subtitle')}>1. About you</h2>
                <p>Two questions to understand who you are.</p>
              </div>
              <div>
                <h2 className={cx('subtitle')}>2. Skills mapping</h2>
                <p>
                  You will see a list of UX & UI skills. Evaluate
                  {` ${name}`}
                  's proficiency for each skill. If not familiar or not observed: skip it.
                </p>
              </div>
              <div>
                <h2 className={cx('subtitle')}>3. Feedback & tips</h2>
                <p>
                  What are
                  {` ${name}`}
                  ’s strengths & development areas?
                </p>
              </div>
              <div>
                <h2 className={cx('subtitle')}>4. Wrap up</h2>
              </div>
            </div>
          </div>
          <div className={cx('stepper')}>
            <div className={cx('greyDot')} />
            <div className={cx('yellowDot')} />
          </div>

          <Button
            type="button"
            onClick={() => history.push('step-1', { name })}
            className={cx('about-you')}
          >
            About you
          </Button>
        </div>
      </div>
    </>
  );
}

export default Process;
