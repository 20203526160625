import React, { useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import Chart from '../../components/Chart';
import styles from './PeerReviewCard.module.css';
import PeerReviewDetailsModal from './PeerReviewDetailsModal';
import useDefaultMedia from '../../hooks/useDefaultMedia';

const cx = classNames.bind(styles);

function PeerReviewCard({
  peerReviews,
  createdAt,
  userSkills,
  className,
  strengths,
  improvementAreas,
  peerSeniorityLevel,
  userSeniorityLevel,
  scopeOfWork,
  peerReviewsDetail,
  userSkillsDetail,
  anecdote,
}) {
  const isWide = useDefaultMedia();
  const date = new Date(createdAt).toLocaleDateString();

  const filterLevels = (macroSkill) => {
    const level = Object.values(macroSkill)[0];
    return level > 0 ? level : null;
  };

  const series = [
    {
      name: 'You',
      data: userSkills.map(filterLevels),
    },
    {
      name: 'Peer review',
      data: peerReviews.map(filterLevels),
    },
  ];

  const categories = peerReviews.map((item) => Object.keys(item)[0].split(' '));

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={className} onClick={handleOpen} role="button">
      <p className={cx('date')}>Received {date}</p>
      <Chart categories={categories} series={series} height="185px" optimized />
      <PeerReviewDetailsModal
        userSkills={isWide ? userSkillsDetail : userSkills}
        peerReviews={isWide ? peerReviewsDetail : peerReviews}
        strengths={strengths}
        improvementAreas={improvementAreas}
        peerSeniorityLevel={peerSeniorityLevel}
        userSeniorityLevel={userSeniorityLevel}
        scopeOfWork={scopeOfWork}
        open={open}
        onClose={handleClose}
        date={date}
        anecdote={anecdote}
      />
    </div>
  );
}

PeerReviewCard.propTypes = {
  className: PropTypes.string,
  peerReviews: PropTypes.arrayOf(PropTypes.object).isRequired,
  createdAt: PropTypes.string.isRequired,
  userSkills: PropTypes.arrayOf(PropTypes.object).isRequired,
  peerSeniorityLevel: PropTypes.number,
  userSeniorityLevel: PropTypes.number,
  strengths: PropTypes.string,
  improvementAreas: PropTypes.string,
  scopeOfWork: PropTypes.arrayOf(PropTypes.number),
  peerReviewsDetail: PropTypes.arrayOf(PropTypes.object),
  userSkillsDetail: PropTypes.arrayOf(PropTypes.object).isRequired,
  anecdote: PropTypes.string,
};

PeerReviewCard.defaultProps = {
  className: '',
  strengths: '',
  improvementAreas: '',
  scopeOfWork: [],
  peerSeniorityLevel: undefined,
  userSeniorityLevel: undefined,
  peerReviewsDetail: [],
  anecdote: '',
};

export default PeerReviewCard;
