import React, { useState, useEffect, Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { FiBarChart2 } from 'react-icons/fi';
import styles from './TheWorld.module.css';
import { API_URL, LEVELS } from '../../Constant';
import { getAverageLevelByMacroSkill } from '../../utils/skills';
import MacroBubbleChart from './MacroBubbleChart';
import Button from '../../components/Button';
import authFetch from '../../utils/authFetch';
import useDefaultMedia from '../../hooks/useDefaultMedia';
import Card from '../../components/Card';
import ConditionalWrapper from '../../utils/ConditionalWrapper';

const cx = classNames.bind(styles);

function TheWorld({ result }) {
  const isWide = useDefaultMedia();
  const userResult = getAverageLevelByMacroSkill(result);

  const [skillMappingsWorldLevels, setSkillMappingsWorldLevels] = useState([]);
  const [macroSkills, setMacroSkills] = useState([]);
  const [skillMappingsCount, setSkillMappingsCount] = useState(0);
  useEffect(() => {
    (async () => {
      const response = await authFetch(`${API_URL}/skills/world`);
      const jsonData = await response.json();
      setSkillMappingsWorldLevels(jsonData.skillMappingsLevels);
      setMacroSkills(jsonData.macroSkills);
      setSkillMappingsCount(jsonData.nbOfSkillMappings);
    })();
  }, []);

  return (
    <ConditionalWrapper
      condition={isWide}
      wrapper={(children) => <Card className={cx('card')} overflow>{children}</Card>}
    >
      {isWide

      && (
      <div className={cx('header')}>
        <h1 className={cx('main-title')}>You and the world</h1>
      </div>
      )}
      {skillMappingsCount > 0 && (
      <div className={cx('grid-container')}>
        <h2 className={cx('title')}>
          {`Based on ${skillMappingsCount} skills mapping.`}
          <br />
          FYI we just launched 🙂.
        </h2>
        {LEVELS.map((level) => (
          <span className={cx('level')} key={level}>
            {level}
          </span>
        ))}
        {macroSkills.map((macroSkill) => {
          const macroUserResult = userResult.find(
            (userMacroSkill) => userMacroSkill.macroSkill === macroSkill.name,
          );
          return (
            <MacroBubbleChart
              key={macroSkill.name}
              userAverageLevel={macroUserResult.averageLevel}
              macroName={macroSkill.name}
              macroCount={skillMappingsCount}
              worldResults={skillMappingsWorldLevels.filter(
                (skillMapping) => skillMapping.Skill.MacroSkillId === macroSkill.id,
              )}
            />
          );
        })}
        {!isWide && (
        <Link className="no-underline text-centered" to="/sign-up">
          <Button className={cx('button')}>
            <div className={cx('button-content')}>
              <FiBarChart2 className={cx('bar-chart-icon')} />
              <p className={cx('button-title')}>Want more?</p>
            </div>
          </Button>
        </Link>
        )}
      </div>
      )}
    </ConditionalWrapper>
  );
}

TheWorld.propTypes = {
  result: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TheWorld;
