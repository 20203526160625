import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import classNames from 'classnames/bind';
import styles from './EditableProfile.module.css';
import authFetch from '../../utils/authFetch';
import { API_URL } from '../../Constant';
import EditingContext from './EditingContext';
import UserProfile from './UserProfile';
import PeerReviewTabPanel from './PeerReviewTabPanel';
import useDefaultMedia from '../../hooks/useDefaultMedia';

const cx = classNames.bind(styles);

function EditableProfile({
  user, setUser, userSkills, peerReviews, peerReviewsDetail, userSkillsDetail,
}) {
  const isWide = useDefaultMedia();

  const [editing, setEditing] = useState(null);

  const removeEmptyLinks = (links) => links.filter((item) => item?.url);

  const saveEdit = async () => {
    const cleanedEditing = { ...editing, links: removeEmptyLinks(editing.links) };
    try {
      const response = await authFetch(`${API_URL}/users/profile/edit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cleanedEditing),
      });

      if (response.ok) {
        setUser(cleanedEditing);
      }
      setEditing(null);
    } catch (error) {
      console.log(error);
    }
  };

  const editContextValue = {
    editing,
    startEditing: () => {
      setEditing(JSON.parse(JSON.stringify({ ...user, currentSituations: user.currentSituations || [] })));
    },
    setEditing,
    saveEdit,
  };


  if (isWide) {
    return (
      <EditingContext.Provider value={editContextValue}>
        <UserProfile
          user={user}
          userSkills={userSkills}
          editable
          peerReviews={peerReviews}
        />
      </EditingContext.Provider>
    );
  }
  return (
    <EditingContext.Provider value={editContextValue}>
      <Tabs>
        <TabList className={cx('tab-list')}>
          <Tab className={cx('tab')} selectedClassName={cx('tab-active')}>
            Peer reviews (
            {peerReviews.length}
            )
          </Tab>
          <Tab className={cx('tab')} selectedClassName={cx('tab-active')}>
            Profile
          </Tab>
        </TabList>
        <TabPanel>
          <PeerReviewTabPanel
            peerReviews={peerReviews}
            userSkills={userSkills}
            peerReviewsDetail={peerReviewsDetail}
            userSkillsDetail={userSkillsDetail}
            userKey={user.key}
          />
        </TabPanel>
        <TabPanel>
          <UserProfile
            user={user}
            userSkills={userSkills}
            editable
            peerReviews={peerReviews}
          />
        </TabPanel>
      </Tabs>
    </EditingContext.Provider>
  );
}

EditableProfile.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  userSkills: PropTypes.arrayOf(PropTypes.object).isRequired,
  peerReviews: PropTypes.arrayOf(PropTypes.object).isRequired,
  peerReviewsDetail: PropTypes.arrayOf(PropTypes.object).isRequired,
  userSkillsDetail: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default EditableProfile;
