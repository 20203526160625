import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { Link } from 'react-router-dom';
import { IoMdShareAlt } from 'react-icons/io';
import Copy from 'react-copy-to-clipboard';

import { toast } from 'react-toastify';
import { API_URL } from '../../Constant';
import authFetch from '../../utils/authFetch';
import styles from './WideSkillMapping.module.css';
import Chart from '../../components/Chart';
import useDefaultMedia from '../../hooks/useDefaultMedia';
import Button from '../../components/Button/Button';

const cx = classNames.bind(styles);

function WideSkillMapping({ user, userSkills, peerReviews }) {
  const isWide = useDefaultMedia();

  const [displayDetail, setDisplayDetail] = useState(true);
  const [userSkillsDetail, setUserSkillsDetail] = useState([]);
  useEffect(() => {
    if (user && isWide) {
      (async () => {
        const response = await authFetch(`${API_URL}/users/${user.key}/skills/detail`);

        if (response.ok) {
          const data = await response.json();

          setUserSkillsDetail(data);
        }
      })();
    }
  }, [user, isWide]);
  const [peerReviewsDetail, setPeerReviewsDetail] = useState([]);
  useEffect(() => {
    if (user && user.isProfileOwner && isWide) {
      (async () => {
        const response = await authFetch(`${API_URL}/peer-review/${user.key}/detail`);

        if (response.ok) {
          const data = await response.json();
          setPeerReviewsDetail(data);
        }
      })();
    }
  }, [user]);

  const categories = (displayDetail ? userSkillsDetail : userSkills).map((item) => (displayDetail ? Object.keys(item)[0] : Object.keys(item)[0].split(' ')));
  const simplePeerReviews = (displayDetail ? peerReviewsDetail : peerReviews).map((el) => el.values);

  const peerReviewsAverage = (displayDetail ? userSkillsDetail : userSkills)
    .map((el) => Object.keys(el)[0])
    .map(
      (name) => simplePeerReviews.reduce(
        (sum, el) => sum + el.find((o) => o[name] != null)[name],
        0,
      ) / simplePeerReviews.length,
    );

  return (
    <>
      <div className={cx('header')}>
        <h1 className={cx('title')}>
          Skills mapping
        </h1>

        <Tabs className={cx('tabs')}>
          <TabList className={cx('tab-list')}>
            <Tab
              className={cx('tab')}
              selectedClassName={cx('tab-active')}
              onClick={() => setDisplayDetail(true)}
            >
              Detail
            </Tab>
            <Tab
              className={cx('tab')}
              selectedClassName={cx('tab-active')}
              onClick={() => setDisplayDetail(false)}
            >
              Overview
            </Tab>
          </TabList>
        </Tabs>
        <div className={cx('graph-toolbar')}>
          {user.isProfileOwner && (
            <>
              <Link to={`/skills-mapping/update/${user.key}`}>
                <Button className={cx('modify-btn')}>
                  <span>Update</span>
                </Button>
              </Link>

              <Copy
                text={`${window.location.origin}/profile/${user.key}`}
                options={{ format: 'text/plain' }}
                onCopy={() =>
                  toast.success(
                    'Link copied. You can now share it. Recipients will not see your peer reviews.',
                    {
                      position: 'bottom-center',
                      autoClose: 4000,
                    },
                  )
                }
              >
                <IoMdShareAlt
                  className={cx('share')}
                  size={18}
                  color="#707070"
                />
              </Copy>
            </>
          )}
        </div>
      </div>
      <div className={cx('chart-container')}>
        <Chart
          height="100%"
          categories={categories}
          legend={user.isProfileOwner}
          series={[
            {
              name: 'You',
              data: (displayDetail ? userSkillsDetail : userSkills).map(
                (item) => Object.values(item)[0],
              ),
            },
            {
              name: 'Peer average',
              data: peerReviewsAverage,
            },
          ]}
          columnWidth={displayDetail ? '45%' : '15%'}
          details
        />
      </div>
    </>
  );
}

export default WideSkillMapping;
