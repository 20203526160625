import React from 'react';
import { useHistory } from 'react-router';
import ImagePage from '../../components/ImagePage';

import { PUBLIC_FOLDER } from '../../Constant';

// @grant window.close
function ThankYou() {
  const history = useHistory();

  const redirectToHome = () => {
    history.push('/');
  };

  return (
    <ImagePage
      title="Thank you!"
      imgSrc={`${PUBLIC_FOLDER}/img/thank_you.png`}
      description="Your peer will be notified by email. Now, you can map your UX-UI skills and start your own peer loop."
      primaryButtonText="Start mapping my skills"
      primaryButtonOnclick={redirectToHome}
    />
  );
}

export default ThankYou;
