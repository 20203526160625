import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './ExperiencesTabPanel.module.css';
import Editable from './Editable';
import EditableNumber from './EditableNumber';
import { ReactComponent as Tie } from './tie.svg';
import CurrentSituations from './CurrentSituations';
import SlidingCheckbox from '../../components/SlidingCheckbox';
import EditingContext from './EditingContext';

const cx = classNames.bind(styles);

function ExperiencesTabPanel({ user }) {
  const {
    lookingForAJob,
    totalYearsOfExperience,
    UXYearsOfExperience,
    soleUX,
    partUXTeam,
    yearsInAgency,
    yearsInStartUps,
    yearsInBigCompanies,
    currentSituations,
    isProfileOwner,
  } = user;
  const jobStatus = lookingForAJob ? 'Looking for a job' : 'Not looking for a job';

  const { editing, setEditing } = useContext(EditingContext);

  const onJobStatusChange = (evt) => {
    setEditing({ ...editing, lookingForAJob: evt.target.checked });
  };

  return (
    <>
      <h2 className={cx('title')}>
        <Tie />
        {user.isProfileOwner ? 'Your' : `${user.firstname}'s`}
        {' '}
        professional context
      </h2>
      <div className={cx('card')}>
        <h3>Years of experience</h3>
        <div className={cx('experience-item')}>
          <p>Total experience</p>
          <p>
            <EditableNumber
              value="totalYearsOfExperience"
              className="experience"
              user={user}
            />
            {' '}
            years
          </p>
        </div>
        <div className={cx('experience-item')}>
          <p>Experience in UX/UI</p>
          <p>
            <EditableNumber
              value="UXYearsOfExperience"
              className="experience"
              user={user}
            />
            {' '}
            years
          </p>
        </div>
        <hr />
        <h3>Job set-up (% of time)</h3>
        <div className={cx('experience-item')}>
          <p>Spent as a sole UX-UIer</p>
          <p>
            <EditableNumber value="soleUX" className="experience" user={user} />
            {' '}
            %
          </p>
        </div>
        <div className={cx('experience-item')}>
          <p>Spent within a UX-UI team</p>
          <p>
            <EditableNumber
              value="partUXTeam"
              className="experience"
              user={user}
            />
            {' '}
            %
          </p>
        </div>
        <hr />
        <CurrentSituations
          currentSituations={currentSituations}
          className={cx('experience-item', 'capitalize')}
        />
        <hr />
        <h3>Years spent in following structures</h3>
        <div className={cx('experience-item')}>
          <p>Agency/ consulting</p>
          <p>
            <EditableNumber
              value="yearsInAgency"
              className="experience"
              user={user}
            />
            {' '}
            years
          </p>
        </div>
        <div className={cx('experience-item')}>
          <p>Start-ups</p>
          <p>
            <EditableNumber
              value="yearsInStartUps"
              className="experience"
              user={user}
            />
            {' '}
            years
          </p>
        </div>
        <div className={cx('experience-item')}>
          <p>Big companies</p>
          <p>
            <EditableNumber
              value="yearsInBigCompanies"
              className="experience"
              user={user}
            />
            {' '}
            years
          </p>
        </div>
        <hr />
        <h3>Job status</h3>
        {editing ? (
          <div className={cx('experience-item')}>
            <p>I&apos;m currently looking for a job</p>
            <SlidingCheckbox
              name="lookingForAJob"
              checked={editing.lookingForAJob}
              onChange={onJobStatusChange}
            />
          </div>
        ) : (
          <p>{jobStatus}</p>
        )}
      </div>
    </>
  );
}

ExperiencesTabPanel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
};

export default ExperiencesTabPanel;
