import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { CSSTransition } from 'react-transition-group';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import TextareaAutosize from 'react-textarea-autosize';
import CopyToClipboard from '../../components/CopyToClipboard';
import styles from './PeerReviewModal.module.css';

import { PUBLIC_FOLDER } from '../../Constant';
import Button from '../../components/Button';
import useDefaultMedia from '../../hooks/useDefaultMedia';

const modalRoot = document.getElementById('modal');
const cx = classNames.bind(styles);

function PeerReviewModal({
  open, onClose, peerReviewLink,
}) {
  const isWide = useDefaultMedia();
  const nodeRef = useRef(null);
  const textAreaContentDefault = `I invite you to review my UX/UI skills as we have worked together.

The process takes 7 to 8 minutes. You do not need to register, your review will be sent anonymously and the report will only be accessible by me.

Your review can help me in self-awareness of my skills, and I would be happy to evaluate your skills as well.

Grateful in advance,

Follow this link: ${peerReviewLink}`;
  const [textAreaText, setTextAreaText] = useState(textAreaContentDefault);
  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const [selectedTab, setSelectedTab] = useState(0);
  const goToInformations = () => {
    setSelectedTab(1);
  };

  const handleClose = () => (isWide && selectedTab === 1 ? setSelectedTab(0) : onClose());

  const selectAll = (evt) => evt.target.select();

  return (
    <CSSTransition
      in={open}
      unmountOnExit
      classNames={{
        enter: cx('slide-up-enter'),
        enterActive: cx('slide-up-enter-active'),
        exit: cx('slide-up-exit'),
        exitActive: cx('slide-up-exit-active'),
      }}
      timeout={500}
      nodeRef={nodeRef}
    >
      <div className={cx('modal')} onClick={handleClose} role="none">
        <div
          ref={nodeRef}
          className={cx('modal-content')}
          role="none"
          onClick={handleChildClick}
        >
          <div className={cx('modal-header')}>
            <button
              type="button"
              className={cx('modal-back-button')}
              onClick={handleClose}
            >
              <MdKeyboardArrowLeft size={24} />
              <p>Back</p>
            </button>
          </div>
          <Tabs
            className={cx('tabs')}
            selectedIndex={selectedTab}
            onSelect={setSelectedTab}
          >
            <TabList className={cx('tab-list')}>
              <Tab className={cx('tab')} selectedClassName={cx('tab-active')}>
                Share
              </Tab>
              <Tab className={cx('tab')} selectedClassName={cx('tab-active')}>
                Instructions
              </Tab>
            </TabList>
            <TabPanel selectedClassName={cx('main')}>
              {isWide && (
                <h1
                  className={cx('title')}
                  style={{
                    backgroundImage: `url(${PUBLIC_FOLDER}/img/brush.png)`,
                  }}
                >
                  Start peer review
                </h1>
              )}
              <div className={cx('card')}>
                <div>
                  <div className={cx('cardIndex')}>Your link</div>
                  <h2 className={cx('cardTitle')}>Adapt, share, grow</h2>
                  <span className={cx('cardDescription')}>
                    Identify your peers and share the link below via LinkedIn
                    messages, emails or WhatsApp.
                  </span>
                  <br />
                </div>
                <img
                  className={cx('cardImage')}
                  alt="share"
                  src={`${PUBLIC_FOLDER}/img/01.png`}
                />
              </div>
              <TextareaAutosize
                className={cx('textArea')}
                defaultValue={textAreaText}
                onFocus={selectAll}
                onChange={(e) => setTextAreaText(e.target.value)}
              />
              <CopyToClipboard text={textAreaText} />
              {isWide && (
                <div className={cx('card', 'white')}>
                  <div className={cx('help-title')}>
                    Need help?
                    <img
                      src={`${PUBLIC_FOLDER}/img/01.png`}
                      alt="Need help?"
                    />
                  </div>
                  <div className={cx('help-content')}>
                    <p>
                      We explain what you can gain from implementing a
                      continuous feedback loop, how it works and how to select
                      your peers.
                    </p>
                    <Button
                      onClick={goToInformations}
                      className={cx('help-btn')}
                    >
                      See instructions
                    </Button>
                  </div>
                </div>
              )}
            </TabPanel>
            <TabPanel selectedClassName={cx('informationMain')}>
              <div className={cx('informationMainContent')}>
                {isWide && (
                  <h1
                    className={cx('title')}
                    style={{
                      backgroundImage: `url(${PUBLIC_FOLDER}/img/brush.png)`,
                    }}
                  >
                    Instructions
                  </h1>
                )}
                <ul>
                  <li>
                    <h2 className={cx('informationTitle')}>Why should I get peer reviewed?</h2>
                    <p className={cx('informationContent')}>
                      For self-awareness & personal development.
                      Collect feedback, improve your skills.
                    </p>
                  </li>
                  <li>
                    <h2 className={cx('informationTitle')}>When should I ask for peer reviews?</h2>
                    <p className={cx('informationContent')}>
                      After a project, a lecture, a talk, a workshop, an interview or a case study.
                    </p>
                  </li>
                  <li>
                    <h2 className={cx('informationTitle')}>How does it work?</h2>
                    <p className={cx('informationContent')}>
                      Share your link with any peer. Reviews are anonymous. Reports are private to you.
                    </p>
                  </li>
                  <li>
                    <h2 className={cx('informationTitle')}>How many peers should I ask?</h2>
                    <p className={cx('informationContent')}>
                      Conversion might be low. We advise to send to 9 peers to get 3 reviews.
                    </p>
                  </li>
                  <li>
                    <h2 className={cx('informationTitle')}>What will peers do?</h2>
                    <p className={cx('informationContent')}>
                      Peers rate your UI/UI skills and elaborate on your strengths and growth areas.
                    </p>
                  </li>
                  <li>
                    <h2 className={cx('informationTitle')}>
                      How should I select my peers?
                    </h2>
                    <p className={cx('informationContent')}>
                      Peers should have witnessed your UX/UI skills and be familiar with UX/UI.
                    </p>
                  </li>
                  <li>
                    <h2 className={cx('informationTitle')}>How will I be notified?</h2>
                    <p className={cx('informationContent')}>
                      You will receive an email when a report is ready.
                      Follow-up directly with your peers to remind them to do
                      the review. 
                    </p>
                  </li>
                </ul>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </CSSTransition>
  );
}

PeerReviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  peerReviewLink: PropTypes.func.isRequired,
};

export default ({
  onClose, open, peerReviewLink,
}) => createPortal(
  <PeerReviewModal
    onClose={onClose}
    open={open}
    peerReviewLink={peerReviewLink}

  />,
  modalRoot,
);
