import React from 'react';
import classNames from 'classnames/bind';
import Card from '../../components/Card';
import styles from './LegalMentions.module.css';

const cx = classNames.bind(styles);

function TermsAndConditions() {
  return (
    <Card>
      <div className={cx('terms-content')}>
        <h2>Terms of Service</h2>
        <p>Last updated: August 9th 2020</p>
        <h3>Introduction</h3>
        <p>
          Welcome to Whatuxru.com (“Company”, “we”, “our”, “us”). These
          Terms of Service (“Terms”, “Terms of Service”) govern your use of our
          web pages located at
          &nbsp;
          <a href="https://whatuxru.com" target="_blank" rel="noreferrer">
            https://whatuxru.com
          </a>
          &nbsp;
          operated by Whatuxru.com
        </p>
        <p>
          Our Privacy Policy also governs your use of our Service and explains
          how we collect, safeguard and disclose information that results from
          your use of our web pages. Please read it here
          &nbsp;
          <a
            href="https://whatuxru.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            https://whatuxru.com/privacy
          </a>
          .
        </p>
        <p>
          Your agreement with us includes these Terms and our Privacy Policy
          (“Agreements”). You acknowledge that you have read and understood
          Agreements, and agree to be bound of them.
        </p>
        <p>
          If you do not agree with (or cannot comply with) Agreements, then you
          may not use the Service, but please let us know by emailing at
          hello@whatuxru.com so we can try to find a solution. These Terms apply
          to all visitors, users and others who wish to access or use Service.
        </p>
        <h3>Communications</h3>
        <p>
          By creating an Account on our Service, you agree to subscribe to
          newsletters, marketing or promotional materials and other information
          we may send. However, you may opt out of receiving any, or all, of
          these communications from us by following the unsubscribe link or by
          emailing at.
        </p>
        <h3>Content</h3>
        <p>
          You are responsible for Content that you provide on or through
          Service, including its legality, reliability, and appropriateness.
        </p>
        <p>
          You represent and warrant that: (i) Content is yours (you own it)
          and/or you have the right to use it and the right to grant us the
          rights and license as provided in these Terms, and (ii) that the
          posting of your Content on or through Service does not violate the
          privacy rights, publicity rights, copyrights, contract rights or any
          other rights of any person or entity. We reserve the right to
          terminate the account of anyone found to be infringing on a copyright.
        </p>
        <p>
          You retain any and all of your rights to any Content you submit, post
          or display on or through Service and you are responsible for
          protecting those rights. We take no responsibility and assume no
          liability for Content you or any third party posts on or through
          Service.
        </p>
        <p>
          Whatuxru.com has the right but not the obligation to monitor and
          edit all Content provided by users.
        </p>
        <h3>Trademarks</h3>
        <p>
          The trademarks, logos and service marks (“Marks”) displayed on the
          Site are the property of Whatuxru or other third parties. You are not
          permitted to use these Marks without the prior written consent of
          Whatuxru or such third party.
        </p>
        <h3>Prohibited Uses</h3>
        <p>
          You may use Service only for lawful purposes and in accordance with
          Terms. You agree not to use Service:
        </p>
        <ol type="a">
          <li>
            In any way that violates any applicable national or international
            law or regulation.
          </li>
          <li>
            For the purpose of exploiting, harming, or attempting to exploit or
            harm minors in any way by exposing them to inappropriate content or
            otherwise.
          </li>
          <li>
            To transmit, or procure the sending of, any advertising or
            promotional material, including any “junk mail”, “chain letter,”
            “spam,” or any other similar solicitation.
          </li>
          <li>
            To impersonate or attempt to impersonate Company, a Company
            employee, another user, or any other person or entity.
          </li>
          <li>
            In any way that infringes upon the rights of others, or in any way
            is illegal, threatening, fraudulent, or harmful, or in connection
            with any unlawful, illegal, fraudulent, or harmful purpose or
            activity.
          </li>
          <li>
            To engage in any other conduct that restricts or inhibits anyone’s
            use or enjoyment of Service, or which, as determined by us, may harm
            or offend Company or users of Service or expose them to liability.
          </li>
        </ol>
        <p>Additionally, you agree not to:</p>
        <ol>
          <li>
            Use Service in any manner that could disable, overburden, damage, or
            impair Service or interfere with any other party’s use of Service,
            including their ability to engage in real time activities through
            Service.
          </li>
          <li>
            Use any robot, spider, or other automatic device, process, or means
            to access Service for any purpose, including monitoring or copying
            any of the material on Service.
          </li>
          <li>
            Use any manual process to monitor or copy any of the material on
            Service or for any other unauthorized purpose without our prior
            written consent.
          </li>
          <li>
            Use any device, software, or routine that interferes with the proper
            working of Service.
          </li>
          <li>
            Introduce any viruses, trojan horses, worms, logic bombs, or other
            material which is malicious or technologically harmful.
          </li>
          <li>
            Attempt to gain unauthorized access to, interfere with, damage, or
            disrupt any parts of Service, the server on which Service is stored,
            or any server, computer, or database connected to Service.
          </li>
          <li>
            Attack Service via a denial-of-service attack or a distributed
            denial-of-service attack.
          </li>
          <li>Take any action that may damage or falsify Company rating.</li>
          <li>
            Otherwise attempt to interfere with the proper working of Service.
          </li>
        </ol>
        <h3>Analytics</h3>
        <p>
          We may use third-party Service Providers to monitor and analyze the
          use of our Service.
        </p>
        <h5>Google Analytics</h5>
        <p>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our Service. This data is shared with
          other Google services. Google may use the collected data to
          contextualise and personalise the ads of its own advertising network.
        </p>
        <p>
          For more information on the privacy practices of Google, please visit
          the Google Privacy Terms web page: &nbsp;

          <a
            href="https://policies.google.com/privacy?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            https://policies.google.com/privacy?hl=en
          </a>
        </p>
        <p>
          We also encourage you to review the Google&apos;s policy for
          safeguarding your data: &nbsp;

          <a
            href="https://support.google.com/analytics/answer/6004245"
            target="_blank"
            rel="noreferrer"
          >
            https://support.google.com/analytics/answer/6004245
          </a>
          .
        </p>
        <h5>Hotjar</h5>
        <p>
          Hotjar is a behavior analytics company that analyses website use,
          providing feedback through tools such as heatmaps, session recordings,
          and surveys. It works with web analytics tools such as Google
          Analytics to offer an insight into how people are navigating websites,
          and how their customer experience can be improved.
        </p>
        <p>
          For more information on the privacy practices of Hotjar, please visit
          the HotjarPrivacy Terms web page: &nbsp;

          <a
            href="https://www.hotjar.com/legal/policies/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.hotjar.com/legal/policies/privacy/
          </a>
          .
        </p>
        <h3>No Use By Minors</h3>
        <p>
          Service is intended only for access and use by individuals at least
          eighteen (18) years old. By accessing or using any of Company, you
          warrant and represent that you are at least eighteen (18) years of age
          and with the full authority, right, and capacity to enter into this
          agreement and abide by all of the terms and conditions of Terms. If
          you are not at least eighteen (18) years old, you are prohibited from
          both the access and usage of Service.
        </p>
        <h3>Accounts</h3>
        <p>
          When you create an account with us, you guarantee that you are above
          the age of 18, and that the information you provide us is accurate,
          complete, and current at all times. Inaccurate, incomplete, or
          obsolete information may result in the immediate termination of your
          account on Service.
        </p>
        <p>
          You are responsible for maintaining the confidentiality of your
          account and password, including but not limited to the restriction of
          access to your computer and/or account. You agree to accept
          responsibility for any and all activities or actions that occur under
          your account and/or password, whether your password is with our
          Service or a third-party service. You must notify us immediately upon
          becoming aware of any breach of security or unauthorized use of your
          account.
        </p>
        <p>
          You may not use as a username the name of another person or entity or
          that is not lawfully available for use, a name or trademark that is
          subject to any rights of another person or entity other than you,
          without appropriate authorization. You may not use as a username any
          name that is offensive, vulgar or obscene.
        </p>
        <p>
          We reserve the right to refuse service, terminate accounts, remove or
          edit content, or cancel orders in our sole discretion.
        </p>
        <h3>Intellectual Property</h3>
        <p>
          Service and its original content (excluding Content provided by
          users), features and functionality are and will remain the exclusive
          property of Whatuxru.com and its licensors. Service is protected
          by copyright, trademark, and other laws of the United States. Our
          trademarks and trade dress may not be used in connection with any
          product or service without the prior written consent of Whatuxru.com.
        </p>
        <h3>Error Reporting and Feedback</h3>
        <p>
          You may provide us either directly at &nbsp;

          <a href="mailto:hello@whatuxru.com">hello@whatuxru.com</a>

          or via
          third party sites and tools with information and feedback concerning
          errors, suggestions for improvements, ideas, problems, complaints, and
          other matters related to our Service (“Feedback”). You acknowledge and
          agree that: (i) you shall not retain, acquire or assert any
          intellectual property right or other right, title or interest in or to
          the Feedback; (ii) Company may have development ideas similar to the
          Feedback; (iii) Feedback does not contain confidential information or
          proprietary information from you or any third party; and (iv) Company
          is not under any obligation of confidentiality with respect to the
          Feedback. In the event the transfer of the ownership to the Feedback
          is not possible due to applicable mandatory laws, you grant Company
          and its affiliates an exclusive, transferable, irrevocable,
          free-of-charge, sub-licensable, unlimited and perpetual right to use
          (including copy, modify, create derivative works, publish, distribute
          and commercialize) Feedback in any manner and for any purpose.
        </p>
        <h4>Sentry</h4>
        <p>
          Sentry is an open-source error tracking solution provided by
          Functional Software Inc. More information is available here: &nbsp;
          <a
            href="https://sentry.io/privacy/"
            target="_blank"
            rel="noreferrer"
          >
          https://sentry.io/privacy/
          </a>
        </p>
        <h4>Datadog</h4>
        <p>
          Datadog is a monitoring platform for cloud applications that bring
          together data from servers, containers, databases, and third-party
          services to make our stack observable. Datadog helps DevOps teams
          avoid downtime, resolve performance issues, and ensure customers are
          getting the best user experience. More information is available here:
          &nbsp;
          <a
            href="https://www.datadoghq.com/legal/privacy/"
            target="_blank"
            rel="noreferrer"
          >
          https://www.datadoghq.com/legal/privacy/
          </a>
        </p>
        <h3>Links To Other Web Sites</h3>
        <p>
          Our Service may contain links to third party web sites or services
          that are not owned or controlled by Whatuxru.com
        </p>
        <p>
          Whatuxru.com has no control over, and assumes no responsibility
          for the content, privacy policies, or practices of any third party web
          sites or services. We do not warrant the offerings of any of these
          entities/individuals or their websites.
        </p>
        <p>
          You acknowledge and agree that Whatuxru.com shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with use of or
          reliance on any such content, goods or services available on or
          through any such third party web sites or services.
        </p>
        <p>
          We strongly advise you to read the terms of service and privacy
          policies of any third party web sites or services that you visit.
        </p>
        <h3>Disclaimer of Warranty</h3>
        <p>
          These services are provided by company on an “as is” and “as
          available” basis. company makes no representations or warranties of
          any kind, express or implied, as to the operation of their services,
          or the information, content or materials included therein. you
          expressly agree that your use of these services, their content, and
          any services or items obtained from us is at your sole risk.
        </p>
        <p>
          Neither company nor any person associated with company makes any
          warranty or representation with respect to the completeness, security,
          reliability, quality, accuracy, or availability of the services.
          Without limiting the foregoing, neither company nor anyone associated
          with company represents or warrants that the services, their content,
          or any services or items obtained through the services will be
          accurate, reliable, error-free, or uninterrupted, that defects will be
          corrected, that the services or the server that makes it available are
          free of viruses or other harmful components or that the services or
          any services or items obtained through the services will otherwise
          meet your needs or expectations.
        </p>
        <p>
          Company hereby disclaims all warranties of any kind, whether express
          or implied, statutory, or otherwise, including but not limited to any
          warranties of merchantability, non-infringement, and fitness for
          particular purpose.
        </p>
        <p>
          The foregoing does not affect any warranties which cannot be excluded
          or limited under applicable law.
        </p>
        <h3>Limitation of Liability</h3>
        <p>
          Except as prohibited by law, you will hold us and our officers,
          directors, employees, and agents harmless for any indirect, punitive,
          special, incidental, or consequential damage, however it arises
          (including attorney&apos;s fees and all related costs and expenses of
          litigation and arbitration, or at trial or on appeal, if any, whether
          or not litigation or arbitration is instituted), whether in an action
          of contract, negligence, or other tortious action, or arising out of
          or in connection with this agreement, including without limitation any
          claim for personal injury or property damage, arising from this
          agreement and any violation by you of any federal, state, or local
          laws, statutes, rules, or regulations, even if company has been
          previously advised of the possibility of such damage. Except as
          prohibited by law, if there is liability found on the part of company,
          it will be limited to the amount paid for the products and/or
          services, and under no circumstances will there be consequential or
          punitive damages. some states do not allow the exclusion or limitation
          of punitive, incidental or consequential damages, so the prior
          limitation or exclusion may not apply to you.
        </p>
        <h3>Termination</h3>
        <p>
          We may terminate or suspend your account and bar access to Service
          immediately, without prior notice or liability, under our sole
          discretion, for any reason whatsoever and without limitation,
          including but not limited to a breach of Terms.
        </p>
        <p>
          If you wish to terminate your account, you may simply discontinue
          using Service.
        </p>
        <p>
          All provisions of Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </p>
        <h3>Governing Law</h3>
        <p>
          These Terms shall be governed and construed in accordance with the
          laws of State of Delaware without regard to its conflict of law
          provisions.
        </p>
        <p>
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service and
          supersede and replace any prior agreements we might have had between
          us regarding Service.
        </p>
        <h3>Changes to Service</h3>
        <p>
          We reserve the right to withdraw or amend our Service, and any service
          or material we provide via Service, in our sole discretion without
          notice. We will not be liable if for any reason all or any part of
          Service is unavailable at any time or for any period. From time to
          time, we may restrict access to some parts of Service, or the entire
          Service, to users, including registered users.
        </p>
        <h3>Amendments to Terms</h3>
        <p>
          We may amend Terms at any time by posting the amended terms on this
          site. It is your responsibility to review these Terms periodically.
        </p>
        <p>
          Your continued use of the Platform following the posting of revised
          Terms means that you accept and agree to the changes. You are expected
          to check this page frequently so you are aware of any changes, as they
          are binding on you.
        </p>
        <p>
          By continuing to access or use our Service after any revisions become
          effective, you agree to be bound by the revised terms. If you do not
          agree to the new terms, you are no longer authorized to use Service.
        </p>
        <h3>Waiver and Severability</h3>
        <p>
          No waiver by Company of any term or condition set forth in Terms shall
          be deemed a further or continuing waiver of such term or condition or
          a waiver of any other term or condition, and any failure of Company to
          assert a right or provision under Terms shall not constitute a waiver
          of such right or provision.
        </p>
        <p>
          If any provision of Terms is held by a court or other tribunal of
          competent jurisdiction to be invalid, illegal or unenforceable for any
          reason, such provision shall be eliminated or limited to the minimum
          extent such that the remaining provisions of Terms will continue in
          full force and effect.
        </p>
        <h3>Acknowledgement</h3>
        <p>
          By using service or other services provided by us, you acknowledge
          that you have read these terms of service and agree to be bound by
          them.
        </p>
        <h3>Contact Us</h3>
        <p>
          Please send your feedback, comments, requests for technical support:
        </p>
        <p>
          By email: &nbsp;

          <a href="mailto:hello@whatuxru.com">hello@whatuxru.com</a>
          .
        </p>
      </div>
    </Card>
  );
}

export default TermsAndConditions;
